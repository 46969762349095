import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbPopoverModule, SuiteRfbTableModule } from '@serpro/ngx-suite-rfb';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { ParcelamentoPgfnComponent, ParcelamentoPgfnPFAtivoComponent, ParcelamentoPgfnPFSuspensoComponent, ParcelamentoPgfnPJAtivoComponent, ParcelamentoPgfnPJSuspensoComponent } from './parcelamento-pgfn.component';
import { ParcelamentoPgfnService } from './parcelamento-pgfn.service';
import { ParcelamentoPgfnTableComponent } from './table/parcelamento-pgfn-table.component';

@NgModule({
    declarations: [
        ParcelamentoPgfnTableComponent,
        ParcelamentoPgfnComponent,
        ParcelamentoPgfnPFAtivoComponent,
        ParcelamentoPgfnPFSuspensoComponent,
        ParcelamentoPgfnPJAtivoComponent,
        ParcelamentoPgfnPJSuspensoComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbPopoverModule
    ],
    providers: [
        ParcelamentoPgfnService
    ]
})
export class ParcelamentoPgfnModule { }
