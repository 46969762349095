import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbPopoverModule, SuiteRfbTableModule, SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { ParcelamentoSicobComponent, ParcelamentoSicobPFAtivoComponent, ParcelamentoSicobPFSuspensoComponent, ParcelamentoSicobPJAtivoComponent, ParcelamentoSicobPJSuspensoComponent } from './parcelamento-sicob.component';
import { ParcelamentoSicobService } from './parcelamento-sicob.service';
import { ParcelamentoSicobTableComponent } from './table/parcelamento-sicob-table.component';

@NgModule({
    declarations: [
        ParcelamentoSicobTableComponent,
        ParcelamentoSicobComponent,
        ParcelamentoSicobPFAtivoComponent,
        ParcelamentoSicobPFSuspensoComponent,
        ParcelamentoSicobPJAtivoComponent,
        ParcelamentoSicobPJSuspensoComponent
    ],
    imports: [
        CommonModule,
        NgxMaskDirective, NgxMaskPipe,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbPopoverModule,
        SuiteRfbToggleModule
    ],
    providers: [
        ParcelamentoSicobService,
        provideNgxMask()
    ]
})
export class ParcelamentoSicobModule { }
