<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo">
      <alerta-mensagem-cib-numerico [onlyFor]="scopeId"></alerta-mensagem-cib-numerico>
      <ng-container *ngIf="ccitrImoveisDoContribuinte && ccitrImoveisDoContribuinte.length > 0; else loadingOrError;">
        <div class="container-fluid">
          <loading-animation *ngIf="!ccitrImoveisDoContribuinte && !initError; else tabelaImoveis;"></loading-animation>
          <ng-template #tabelaImoveis>
            <table suiteRfbTable>
              <thead>
                <tr>
                  <th class="text-center min-width">Ações</th>
                  <th class="text-center min-width">
                    <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
                  </th>
                  <th class="text-center">CIB {{this.setado}}</th>
                  <th class="text-center">Nome do Imóvel</th>
                  <th class="text-center">Situação</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let item of ccitrImoveisDoContribuinte | currentPage: currentPage">
                  <tr class="vertical-align-middle">
                    <td class="text-center">
                      <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
                      <ng-template #menuAcoes>
                        <div class="btn-group-vertical" role="group">
                          <button [disabled]="false" type="button" class="btn btn-default" (click)="consultaCreditos(item); botaoAcoes.hide()">
                            Créditos Tributários
                          </button>
                          <button [disabled]="false" type="button" class="btn btn-default" (click)="exibirPagamentosDARF(item); botaoAcoes.hide()">
                            Pagamentos DARF
                          </button>
                          <button [disabled]="false" type="button" class="btn btn-default" (click)="exibirPagamentoTDA(item); botaoAcoes.hide()">
                            Pagamentos TDA
                          </button>
                          <button [disabled]="false" type="button" class="btn btn-default" (click)="obterCompensacoes(item); botaoAcoes.hide()">
                            Compensações
                          </button>
                          <button [disabled]="false" type="button" class="btn btn-default" (click)="exibirEventos(item); botaoAcoes.hide()">
                            Eventos
                          </button>
                          <button [disabled]="false" type="button" class="btn btn-default" (click)="exibirCTDepurados(item); botaoAcoes.hide()">
                            Recuperar CT Depurado
                          </button>
                        </div>
                      </ng-template>
                    </td>
                    <td>
                      <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                    </td>
                    <td class="text-center">
                      <div tooltip="Consultar Detalhes do CIB" (click)='consultarDetalheCAFIR(item)'>
                        <span class="link">{{  item.nirf | formatCibPipe  }}</span>
                      </div>
                    </td>
                    <td class="text-left">{{ item.nomeImovel }}</td>
                    <td class="text-left">{{ item.situacaoImovel }}</td>
                  </tr>

                  <ng-container *ngIf="rowToggle.show">
                    <tr></tr>
                    <tr>
                        <td colspan="5" class="background-inherit">
                          <div class="contorno-ccitr">
                          <via-detalhe-ccitr-imoveis-do-contribuinte [item]="item"></via-detalhe-ccitr-imoveis-do-contribuinte>
                          </div>
                        </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>

              <tfoot>
                <tr class="tabela-acoes text-left">
                <td colspan="5">
                  <via-pagination [itemAmount]="ccitrImoveisDoContribuinte.length" [(page)]="currentPage"></via-pagination>
                  </td>
              </tr>
              </tfoot>
            </table>
          </ng-template>
        </div>
    </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
