import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FeatureAction } from 'src/app/shared/model/feature';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ParametrosPesquisaCcitr } from "../../../../models/parametros-pesquisa-ccitr";
import { CcitrImoveisDoContribuinteComponent } from '../imoveis-do-contribuinte/ccitr-imoveis-do-contribuinte.component';
import { CriterioSelecaoCcitr } from './../../../../models/parametros-pesquisa-ccitr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertaCibComponent } from '../alerta-cib/alerta-cib.component';

@Component({
  templateUrl: './filtros-pesquisa-ccitr.component.html',
  styleUrls: ['./filtros-pesquisa-ccitr.component.css']
})
export class FiltrosPesquisaCcitrComponent implements IDynamicPanel {

  titulo = 'Cobrança - CCITR - Consultar';

  ni: string;

  exibeResultado: boolean = false;
  filtroColapsado: boolean = false;
  desabilitaBotaoBuscar: boolean = false;

  initError: Error;

  formGroup: UntypedFormGroup;

  parametros: ParametrosPesquisaCcitr;
  @ViewChild('buscar') botaoBuscar: ElementRef;

  cibName: string = 'cib';
  cpfName: string = 'cpf';
  cnpjName: string = 'cnpj';
  cnpjBasicoName: string = 'cnpjBasico';
  exercicioDeName: string = 'exercicioDe';
  exercicioAteName: string = 'exercicioAte';
  tipoLancamentoName: string = 'tipoLancamento';
  criterioSelecaoName: string = "criterioSelecao";
  criterioMarcacaoName: string = 'criterioMarcacao';

  radioSelected: string;

  readonly criterioSelecaoMaed = CriterioSelecaoCcitr.CREDITOS_SOMENTE_MAED;
  readonly criterioSelecaoTodos = CriterioSelecaoCcitr.CREDITOS_TODOS;
  readonly criterioMarcacaoVencimento = false;

  constructor(
    private formBuilder: FormBuilder,
    private dynamicModalRequest: DynamicModalRequestService,
    private modalService: BsModalService) { }

  init(ni: string): void {

    let cpf: string = '';
    let cnpj: string = '';
    let cnpjBasico: string = '';
    let disableAllControls: boolean = false;

    if (ni) {
      if (ni.length > 11) {
        cnpj = FormatCnpjPipe.formatCNPJ(ni);
        this.ni = cnpj;
      } else if (ni.length == 8) {
        cnpjBasico = FormatCnpjPipe.formatCNPJ(ni);
        this.ni = cnpjBasico;
      } else {
        cpf = FormatCpfPipe.formatCPF(ni);
        this.ni = cpf;
      }
      disableAllControls = true;
    }

    this.formGroup = this.formBuilder.group({
      cib: [''],
      cpf: [cpf],
      cnpj: [cnpj],
      cnpjBasico: [cnpjBasico],
      exercicioDe: [''],
      exercicioAte: [''],
      tipoLancamento: [CriterioSelecaoCcitr.CREDITOS_TODOS],
      criterioMarcacao: ['']
    });
    this.radioSelected = CriterioSelecaoCcitr.CREDITOS_TODOS;

    if (disableAllControls) {
      this.enableDisableControls(disableAllControls);
    } else {
      this.desabilitaBotaoBuscar = this.disableBotaoBuscar();
      this.formGroup.valueChanges.subscribe(
        (status) => {
          this.enableDisableControls();
        }
      );
    }
  }

  enableDisableControls(disableAll: boolean = false) {
    if (!disableAll) {
      if (this.disableCpf()) this.cpfCtrl.disable({ emitEvent: false })
      else this.cpfCtrl.enable({ emitEvent: false });

      if (this.disableCnpj()) this.cnpjCtrl.disable({ emitEvent: false })
      else this.cnpjCtrl.enable({ emitEvent: false });

      if (this.disableCnpjBasico()) this.cnpjBasicoCtrl.disable({ emitEvent: false })
      else this.cnpjBasicoCtrl.enable({ emitEvent: false });

      if (this.disableCib()) this.cibCtrl.disable({ emitEvent: false })
      else this.cibCtrl.enable({ emitEvent: false });

      this.desabilitaBotaoBuscar = this.disableBotaoBuscar();

    } else {
      this.cpfCtrl.disable({ emitEvent: false });
      this.cnpjCtrl.disable({ emitEvent: false });
      this.cnpjBasicoCtrl.disable({ emitEvent: false });
    }
  }

  disableCib(): boolean {
    return (this.cpf?.length > 0 || this.cnpj?.length > 0 || this.cnpjBasico?.length > 0);
  }

  disableCpf(): boolean {
    return (this.cnpj?.length > 0 || this.cnpjBasico?.length > 0 || (this.cib?.length > 0));
  }

  disableCnpj(): boolean {
    return (this.cpf?.length > 0 || this.cnpjBasico?.length > 0 || (this.cib?.length > 0));
  }

  disableCnpjBasico(): boolean {
    return (this.cpf?.length > 0 || this.cnpj?.length > 0 || (this.cib?.length > 0));
  }

  disableBotaoBuscar(): boolean {
    return (this.cpf == null || this.cpf?.length == 0)
      && (this.cnpj == null || this.cnpj?.length == 0)
      && (this.cnpjBasico == null || this.cnpjBasico?.length == 0)
      && (this.cib == null || this.cib?.length < 8);
  }

  private get cib(): string {
    const maskedCib = this.formGroup.get(this.cibName).value as string;
    return maskedCib?.replace(/[^\A-Z0-9]+/g, '');
  }

  get cibCtrl(): FormControl {
    return this.formGroup.get(this.cibName) as FormControl;
  }

  private get cpf(): string {
    const maskedCpf = this.formGroup.get(this.cpfName).value as string;
    return maskedCpf?.replace(/[^\d]+/g, '');
  }

  get cpfCtrl(): UntypedFormControl {
    return this.formGroup.get(this.cpfName) as UntypedFormControl;
  }

  private get cnpj(): string {
    const maskedCnpj = this.formGroup.get(this.cnpjName).value as string;
    return maskedCnpj?.replace(/[^\d]+/g, '');
  }

  get cnpjCtrl(): UntypedFormControl {
    return this.formGroup.get(this.cnpjName) as UntypedFormControl;
  }

  private get cnpjBasico(): string {
    const maskedCnpjBasico = this.formGroup.get(this.cnpjBasicoName).value as string;
    return maskedCnpjBasico?.replace(/[^\d]+/g, '');
  }

  get cnpjBasicoCtrl(): UntypedFormControl {
    return this.formGroup.get(this.cnpjBasicoName) as UntypedFormControl;
  }

  private get exercicioDe(): string {
    return this.formGroup.get(this.exercicioDeName).value as string;
  }

  private get exercicioAte(): string {
    return this.formGroup.get(this.exercicioAteName).value as string;
  }

  private get tipoLancamento(): string {
    return this.formGroup.get(this.tipoLancamentoName).value as string;
  }

  private get criterioMarcacao(): string {
    return this.formGroup.get(this.criterioMarcacaoName).value as string;
  }

  private get cnpjPreenchido(): string {
    return (this.cnpj ? this.cnpj : this.cnpjBasico);
  }

  private get indicadorCnpjBasico(): string {
    return (this.cnpjBasico ? '1' : '0');
  }

  private get vencidosAVencer(): string {
    return (this.criterioMarcacao ? '1' : '0');
  }

  public containsOnlyDigits(str: string) {
    return /^\d+$/.test(str);
  }
  
  eNuloOuBranco(str: string): boolean {
    if(str == null || str == '') return true; 
  }

  eNumerico(str: string): boolean {
    if (this.containsOnlyDigits(str)) return true; 
  }
  
  emitirAlertaCibAlfa(){
    let bsModalRef;
    bsModalRef = this.modalService.show(AlertaCibComponent);
    bsModalRef.content.resultado.subscribe(
        resultado =>{
            bsModalRef.hide();
            resultado = "fechar";
        }
    )
  }

  onSubmit(): void {  

    if(this.eNuloOuBranco(this.cib) || this.eNumerico(this.cib)){
      this.parametros = {
        nirf: this.cib,
        cpfContribuinte: this.cpf,
        cnpjContribuinte: this.cnpjPreenchido,
        indicadorCnpjBasico: this.indicadorCnpjBasico,
        tipoLancamento: this.tipoLancamento,
        vencidosAVencer: this.vencidosAVencer,
        exercicioInicial: this.exercicioDe,
        exercicioFinal: this.exercicioAte,
        atendimento: this.ni ? true : false
      }
  
      if (this.ni) {
        this.exibeResultado = true;
        this.filtroColapsado = true;
      } else {
        let feature = new FeatureAction();
        feature.class = CcitrImoveisDoContribuinteComponent;
        feature.parametros = this.parametros;
        this.dynamicModalRequest.abrirModal(feature);
      }
    }
    else{
      this.emitirAlertaCibAlfa();
    }
  }

  public esconderResultado(): void {
    this.exibeResultado = false;
    this.filtroColapsado = false;
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

  onReset() {
    if (!this.ni) {
      this.formGroup.get(this.cpfName)?.reset();
    }

    this.formGroup.get(this.cibName)?.reset();
    this.formGroup.get(this.cpfName)?.reset();
    this.formGroup.get(this.cnpjName)?.reset();
    this.formGroup.get(this.cnpjBasicoName)?.reset();
    this.formGroup.get(this.exercicioAteName)?.reset();
    this.formGroup.get(this.criterioMarcacaoName)?.setValue(false);
    this.formGroup.get(this.exercicioDeName)?.setValue('');
    this.formGroup.get(this.exercicioAteName)?.setValue('');
    this.formGroup.get(this.tipoLancamento)?.setValue(CriterioSelecaoCcitr.CREDITOS_TODOS);
  }
}