import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedModule } from "src/app/shared/shared.module";
import { SitfisPipesModule } from "../../pipes/sitfis-pipes.module";
import { DebitoProcessoSiefModule } from "../debito-processo-sief/debito-processo-sief.module";
import { ProcessoSiefSuiteRfbModule } from "./processo-sief-suite-rfb.module";
import {
  ProcessoSiefComponent,
  ProcessoSiefPFAtivoComponent,
  ProcessoSiefPFSuspensoComponent,
  ProcessoSiefPJAtivoComponent,
  ProcessoSiefPJSuspensoComponent
} from "./processo-sief.component";
import { ProcessoSiefService } from "./processo-sief.service";
import { ProcessoSiefTableComponent } from './table/processo-sief-table.component';

@NgModule({
    declarations: [
        ProcessoSiefComponent,
        ProcessoSiefPJAtivoComponent,
        ProcessoSiefPJSuspensoComponent,
        ProcessoSiefPFAtivoComponent,
        ProcessoSiefPFSuspensoComponent,
        ProcessoSiefTableComponent,
    ],
    imports: [
        CommonModule,
        PipesModule,
        SharedModule,
        SitfisPipesModule,
        ProcessoSiefSuiteRfbModule,
        DebitoProcessoSiefModule,
    ],
    providers: [
        ProcessoSiefService,
    ]
})
export class ProcessoSiefModule { }
