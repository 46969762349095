<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo" [collapsed]="false">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

    <div class="row">
      <div class="col-md-12">
        <via-ccpf-table-componentes
          [atendimento]="atendimento"
          [cpf]="cpf"
          [credito]="credito"
          [componentes]="componentes"
          [exibirAcoes]="false">
        </via-ccpf-table-componentes>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label [for]="qtdCotasName" class="control-label">Nova Quantidade de Quotas</label>
                <input
                  class="form-control"
                  type="text"
                  [formControlName]="qtdCotasName"
                  autocomplete="off"
                  suiteRfbNumbersOnly>
              </div>
            </div>

            <div class="col-md-8" style="margin-top: 25px;">
              <div class="form-group">
                <div class="text-center">
                  <button
                    type="reset"
                    class="btn btn-default"
                    [disabled]="!formGroup.dirty">
                    Limpar
                  </button>

                  <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="formGroup.invalid"
                    (click)="onAlterarQtdCotas()">
                    Alterar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </suite-rfb-collapse>
</div>
