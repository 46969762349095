import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ParcelamentoSIPADESuiteRfbModule } from './parcelamento-sipade-suite-rfb.module';
import { ParcelamentoSIPADECobrancaComponent, ParcelamentoSIPADEComponent, ParcelamentoSIPADEExigibilidadeComponent } from './parcelamento-sipade.component';
import { ParcelamentoSIPADEService } from './parcelamento-sipade.service';
import { ParcelamentoSIPADETableComponent } from './table/parcelamento-sipade-table.component';

@NgModule({
    declarations: [
        ParcelamentoSIPADEComponent,
        ParcelamentoSIPADETableComponent,
        ParcelamentoSIPADECobrancaComponent,
        ParcelamentoSIPADEExigibilidadeComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SuiteRfbToggleModule,
        TooltipModule,
        ParcelamentoSIPADESuiteRfbModule
    ],
    providers: [
        ParcelamentoSIPADEService
    ]
})
export class ParcelamentoSIPADEModule { }
