import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { SuiteRfbPaginationModule, SuiteRfbCollapseModule, SuiteRfbTableModule, SuiteRfbPopoverModule, SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';
import { AguiaComponent, AguiaPFComponent, AguiaPJComponent } from './aguia.component';
import { AguiaService } from './aguia.service';
import { AguiaTableComponent } from './aguia-table/aguia-table.component';

@NgModule( {
    declarations: [
        AguiaComponent,
        AguiaPFComponent,
        AguiaPJComponent,
        AguiaTableComponent,
    ],
    imports: [
        CommonModule,
        NgxMaskDirective, 
        NgxMaskPipe,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbPopoverModule,
        SuiteRfbToggleModule,
    ],
    providers: [
        AguiaService,
        provideNgxMask()
    ]
})
export class AguiaModule {}
