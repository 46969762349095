import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DynamicModalComponent } from '../shared/dynamic-modal/dynamic-modal.component';
import { DynamicActionPanelComponent } from '../shared/dynamic-panel/dynamic-action-panel/dynamic-action-panel.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { AguiaModule } from './components/aguia/aguia.module';
import { ArrolamentoBensSiefModule } from './components/arrolamento-bens-sief/arrolamento-bens-sief.module';
import { CnpjsVinculadosModule } from './components/cnpjs-vinculados/cnpjs-vinculados.module';
import { ContaCorrenteItrModule } from './components/conta-corrente-itr/conta-corrente-itr.module';
import { CCPFModule } from './components/conta-corrente-pf/ccpf.module';
import { DarfMensagensComponent } from './components/darf/darf-mensagens.component';
import { DarfModule } from './components/darf/darf.module';
import { DasMensagensComponent } from './components/das/das-mensagens.component';
import { DasModule } from './components/das/das.module';
import { DebitoDividaModule } from './components/debito-divida/debito-divida.module';
import { DebitoProfiscModule } from './components/debito-profisc/debito-profisc.module';
import { DebitoSicobModule } from './components/debito-sicob/debito-sicob.module';
import { DebitoSiefModule } from './components/debito-sief/debito-sief.module';
import { DeclaracaoAusenteModule } from './components/declaracao-ausente/declaracao-ausente.module';
import { DeclaracaoIrpfMalhaModule } from './components/declaracao-irpf-malha/declaracao-irpf-malha.module';
import { ImpedimentoCndModule } from './components/impedimento-cnd/impedimento-cnd.module';
import { InfoCadastraisPFModule } from './components/info-cadastrais-pf/info-cadastrais-pf.module';
import { InfoCadastraisPJModule } from './components/info-cadastrais-pj/info-cadastrais-pj.module';
import { InscricaoPgfnModule } from './components/inscricao-pgfn/inscricao-pgfn.module';
import { IrregularidadeCadastralModule } from './components/irregularidade-cadastral/irregularidade-cadastral.module';
import { LiberacaoCndModule } from './components/liberacao-cnd/liberacao-cnd.module';
import { ParcelamentoDividaModule } from './components/parcelamento-divida/parcelamento-divida.module';
import { ParcelamentoPaexModule } from './components/parcelamento-paex/parcelamento-paex.module';
import { ParcelamentoPgfnModule } from './components/parcelamento-pgfn/parcelamento-pgfn.module';
import { ParcelamentoRefisModule } from './components/parcelamento-refis/parcelamento-refis.module';
import { ParcelamentoSicobModule } from './components/parcelamento-sicob/parcelamento-sicob.module';
import { ParcelamentoSIEFPARModule } from './components/parcelamento-siefpar/parcelamento-siefpar.module';
import { ParcelamentoSimplesNacionalModule } from './components/parcelamento-simples-nacional/parcelamento-simples-nacional.module';
import { ParcelamentoSIPADEModule } from './components/parcelamento-sipade/parcelamento-sipade.module';
import { ParcelamentoSisparModule } from './components/parcelamento-sispar/parcelamento-sispar.module';
import { ProcessoProfiscModule } from './components/processo-profisc/processo-profisc.module';
import { ProcessoSiefModule } from './components/processo-sief/processo-sief.module';
import { RelatorioApoioCndModule } from './components/relatorio-apoio-cnd/relatorio-apoio-cnd.module';
import { SituacaoFiscalAtendimentoPfComponent } from './components/situacao-fiscal-atendimento-pf/situacao-fiscal-atendimento-pf.component';
import { SituacaoFiscalAtendimentoPjComponent } from './components/situacao-fiscal-atendimento-pj/situacao-fiscal-atendimento-pj.component';
import { SituacaoFiscalPfComponent } from './components/situacao-fiscal-pf.component';
import { SituacaoFiscalPjComponent } from './components/situacao-fiscal-pj.component';
import { SituacaoFiscalVinculosComponent } from './components/situacao-fiscal-vinculos.component';
import { SocioAdminQSAModule } from './components/socio-admin-qsa/socio-admin-qsa.module';
import { SitfisPipesModule } from './pipes/sitfis-pipes.module';
import { SitFisSuiteRfbModule } from './sitfis-suite-rfb.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        PipesModule.forRoot(),
        TooltipModule.forRoot(),
        SitFisSuiteRfbModule,
        PipesModule,
        SitfisPipesModule,
        SharedModule,
        AguiaModule,
        ContaCorrenteItrModule,
        CCPFModule,
        DarfModule,
        DasModule,
        InscricaoPgfnModule,
        IrregularidadeCadastralModule,
        SocioAdminQSAModule,
        InfoCadastraisPFModule,
        InfoCadastraisPJModule,
        ImpedimentoCndModule,
        ParcelamentoDividaModule,
        ParcelamentoSicobModule,
        ParcelamentoSisparModule,
        ParcelamentoSIEFPARModule,
        ParcelamentoSIPADEModule,
        ParcelamentoPaexModule,
        ParcelamentoPgfnModule,
        ParcelamentoSimplesNacionalModule,
        ParcelamentoRefisModule,
        ProcessoProfiscModule,
        DebitoProfiscModule,
        DebitoDividaModule,
        DebitoSicobModule,
        DebitoSiefModule,
        ProcessoSiefModule,
        ArrolamentoBensSiefModule,
        CnpjsVinculadosModule,
        DeclaracaoAusenteModule,
        LiberacaoCndModule,
        RelatorioApoioCndModule,
        DeclaracaoIrpfMalhaModule
    ],
    declarations: [
        SituacaoFiscalPfComponent,
        SituacaoFiscalPjComponent,
        SituacaoFiscalVinculosComponent,
        SituacaoFiscalAtendimentoPfComponent,
        SituacaoFiscalAtendimentoPjComponent
    ],
    exports: [
        SituacaoFiscalPfComponent,
        SituacaoFiscalPjComponent,
        SituacaoFiscalVinculosComponent,
        SituacaoFiscalAtendimentoPfComponent,
        SituacaoFiscalAtendimentoPjComponent
    ],
    providers: [
        DatePipe
    ]
})
export class SitFisModule { }
