import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbTableModule, SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { ImpedimentoCndComponent, ImpedimentoCndPFPgfnComponent, ImpedimentoCndPFRfbComponent, ImpedimentoCndPJPgfnComponent, ImpedimentoCndPJRfbComponent } from './impedimento-cnd.component';
import { ImpedimentoCndService } from './impedimento-cnd.service';
import { ImpedimentoCndTableComponent } from './table/impedimento-cnd-table.component';

@NgModule({
    declarations: [
        ImpedimentoCndTableComponent,
        ImpedimentoCndComponent,
        ImpedimentoCndPJRfbComponent,
        ImpedimentoCndPJPgfnComponent,
        ImpedimentoCndPFRfbComponent,
        ImpedimentoCndPFPgfnComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbToggleModule
    ],
    providers: [
        ImpedimentoCndService
    ]
})
export class ImpedimentoCndModule { }
