import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DarfMensagensComponent } from './darf-mensagens.component';
import { DarfVisualizacaoComponent } from './darf-visualizacao.component';
import { DarfService } from './darf.service';

@NgModule( {
    declarations: [
        DarfMensagensComponent,
        DarfVisualizacaoComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
    ],
    providers: [
        DarfService
    ]
})
export class DarfModule { }