import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DetalheEventoPjComponent } from './components/detalhe/detalhe-evento-pj.component';
import { EventosPjComponent } from './components/eventos-pj.component';
import { EventosPjSuiteRfbModule } from './eventos-pj-suite-rfb.module';
import { EventosPjService } from './services/eventos-pj.service';

@NgModule({
  declarations: [
    EventosPjComponent,
    DetalheEventoPjComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    NgxMaskDirective, NgxMaskPipe,
    EventosPjSuiteRfbModule
  ],
  exports: [
    EventosPjComponent
  ],
  providers: [
    EventosPjService,
    provideNgxMask()
  ]
})
export class EventosPjModule { }
