import { isWarning } from './../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoNIImovel } from './../../../../models/tipo-ni-imovel-ccitr';
import { RecuperaCTDepuradoComponent } from './../recupera-ct-depurado/recupera-ct-depurado.component';
import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CcitrImoveisDoContribuinteDTO } from './model/ccitr-imoveis-do-contribuinte';
import { ParametrosPesquisaCcitr } from "../../../../models/parametros-pesquisa-ccitr";
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { CCITRService } from '../../services/ccitr.service';
import { ConsultaPagamentosTDAComponent } from '../consulta-pagamentos-tda/consulta-pagamentos-tda.component';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { CcitrBase } from '../../base/CcitrBase.component';
import { CcitrConsultaCompensacoesComponent } from '../consulta-compensacoes/ccitr-consulta-compensacoes.component';
import { ParametrosConsultaCompensacoesCcitr } from 'src/app/cobranca/models/parametros-consulta-compensacoes-ccitr';
import { CcitrConsultaCreditosComponent } from 'src/app/cobranca/components/ccitr/components/consulta-creditos/ccitr-consulta-creditos.component';
import { CcitrPagamentosDarfComponent } from '../pagamentos-darf/ccitr-pagamentos-darf.component';
import { DetalheImovelRuralComponent } from 'src/app/cafir/components/detalhe/detalhe-imovel-rural.component';
import { EventosFiltrosCCITR } from '../eventos-filtros/eventos-filtros.component';

@Component({
  selector: 'via-ccitr-imoveis-do-contribuinte',
  templateUrl: './ccitr-imoveis-do-contribuinte.component.html',
  styleUrls: ['./ccitr-imoveis-do-contribuinte.component.css','../../base/ccitr.css'],
  providers: [ScopedMessageService]})

export class CcitrImoveisDoContribuinteComponent extends CcitrBase implements IDynamicPanel, OnInit, OnChanges, OnDestroy{

  @Input() parametros: ParametrosPesquisaCcitr;
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  classeModal: string;

  cpfContribuinte: string = '';
  cnpjContribuinte: string = '';

  updateSubscription: Subscription;

  ccitrImoveisDoContribuinte: CcitrImoveisDoContribuinteDTO[];

  scopeId: string;

  initError: Error;
  stopLoading = false;
  showCloseModal = true;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  private executouObterDados = false;

  public init(ni: string, params?: OptionalParams): void {

    super.init(ni, params);
    this.showCloseModal = false;
    this.parametros = params as ParametrosPesquisaCcitr;

    this.obterCcitrImoveisDoContribuinte();
    console.log('---',this.parametros);
  }

  constructor(
    private cCITRService: CCITRService,
    private dynamicModalService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private messageService: ScopedMessageService) {
    super();
  }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
    this.formataTitulo(this.parametros, 'CCITR - Consultar CIB');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if(this.titulo) {
      this.recarregar();
    }
  }

  public ngOnDestroy(): void {
    if(this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  public recarregar(): void {
    this.initError = null;
    this.obterCcitrImoveisDoContribuinte();
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterCcitrImoveisDoContribuinte();
    }
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(row => row.show = show);
  }

  private setaValorCPFouCNPJContribuinte(cib: string): void {

      let tipoNiProprietario = this.ccitrImoveisDoContribuinte.filter(i => i.nirf === cib)[0].tipoNiProprietario;
      let niProprietario = this.ccitrImoveisDoContribuinte.filter(i => i.nirf === cib)[0].niProprietario;

      if (tipoNiProprietario == TipoNIImovel.CNPJ || tipoNiProprietario == TipoNIImovel.CNPJ2)
        this.cnpjContribuinte = niProprietario;
      else if (tipoNiProprietario == TipoNIImovel.CPF) {
        // Preenchendo apenas o CIB, outro serviço é chamado. Este retorna o CPF com 14 caracteres.
        this.cpfContribuinte = niProprietario.length > 11 ? niProprietario.substring(3,niProprietario.length) : niProprietario;
      }

  }

  private obterCcitrImoveisDoContribuinte(): void {
    this.executouObterDados = true;

    this.cCITRService.obterImoveisDoContribuinte(this.parametros).subscribe(
      response => {
        this.ccitrImoveisDoContribuinte = response;
        if (isEmpty(response)) {
          this.messageService.showInfo('Nenhum registro encontrado');
        }
        else{
          var mensagem = 'Créditos Tributários a partir do exercício 2022 e CIBs alfanuméricos estão disponíveis para consulta no Sief-Fiscel.';
          this.messageService.showWarning(mensagem, mensagem);
        }
      },
      (err: HttpErrorResponse) => {
      if (isWarning(err.error)) {
        this.messageService.showWarning(err.error.message);
        return null;
      } else {
        this.messageService.showErrorException('Ocorreu um erro durante a consulta dos imóveis do contribuinte', err);
        this.initError = err;
      }
    }
    ).add(() => {
      this.stopLoading = true;
    });
  }

  exibirPagamentoTDA(imovelRural: CcitrImoveisDoContribuinteDTO): void {

    this.setaValorCPFouCNPJContribuinte(imovelRural.nirf);

    let parametrosPesquisa = {
      cnpjContribuinte: this.cnpjContribuinte,
      cpfContribuinte: this.cpfContribuinte,
      exercicioInicial: this.parametros.exercicioInicial,
      exercicioFinal: this.parametros.exercicioFinal,
      tipoLancamento: this.parametros.tipoLancamento,
      indicadorCnpjBasico: this.parametros.indicadorCnpjBasico,
      nirf: imovelRural.nirf,
      atendimento: this.atendimento
    } as ParametrosPesquisaCcitr;

    const consultaPagamentosDARFFeature = {
      id: `ConsultaCCITR_PagamentoTDA_${imovelRural.nirf}`,
      title: 'Pagamentos - Consultar',
      class: ConsultaPagamentosTDAComponent,
      parametros: parametrosPesquisa
    } as FeatureAction;

    if (this.atendimento) {
      consultaPagamentosDARFFeature.active = true;
      consultaPagamentosDARFFeature.rolesAllowed = [];
      consultaPagamentosDARFFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(consultaPagamentosDARFFeature);
      return;
    }

    this.dynamicModalService.abrirModal(consultaPagamentosDARFFeature);
  }

  exibirEventos(imovelRural: CcitrImoveisDoContribuinteDTO): void {

    this.setaValorCPFouCNPJContribuinte(imovelRural.nirf);

    let parametrosConsultaEventos = {
      cnpjContribuinte: this.cnpjContribuinte,
      cpfContribuinte: this.cpfContribuinte,
      exercicioInicial: this.parametros.exercicioInicial,
      exercicioFinal: this.parametros.exercicioFinal,
      tipoLancamento: this.parametros.tipoLancamento,
      indicadorCnpjBasico: this.parametros.indicadorCnpjBasico,
      nirf: imovelRural.nirf,
      atendimento: this.atendimento
    } as ParametrosPesquisaCcitr;

    const consultaEventosFeature = {
      id: `ConsultaCCITR_ParametrosEventos_${imovelRural.nirf}`,
      title: 'Eventos - Consultar',
      class: EventosFiltrosCCITR,
      parametros: parametrosConsultaEventos
    } as FeatureAction;

    if (this.atendimento) {
      consultaEventosFeature.active = true;
      consultaEventosFeature.rolesAllowed = [];
      consultaEventosFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(consultaEventosFeature);
      return;
    }

    this.dynamicModalService.abrirModal(consultaEventosFeature);
  }

  exibirCTDepurados(imovelRural: CcitrImoveisDoContribuinteDTO): void {

    this.setaValorCPFouCNPJContribuinte(imovelRural.nirf);

    let parametrosRecuperaCTDepurado = {
      cnpjContribuinte: this.cnpjContribuinte,
      cpfContribuinte: this.cpfContribuinte,
      tipoLancamento: this.parametros.tipoLancamento,
      indicadorCnpjBasico: this.parametros.indicadorCnpjBasico,
      nirf: imovelRural.nirf,
      atendimento: this.atendimento
    } as ParametrosPesquisaCcitr;

    const recuperaCTDepuradoFeature = {
      id: `ConsultaCCITR_CT_Depurado_${imovelRural.nirf}`,
      title: 'CT Depurado - Consultar',
      class: RecuperaCTDepuradoComponent,
      parametros: parametrosRecuperaCTDepurado
    } as FeatureAction;

    if (this.atendimento) {
      recuperaCTDepuradoFeature.active = true;
      recuperaCTDepuradoFeature.rolesAllowed = [];
      recuperaCTDepuradoFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(recuperaCTDepuradoFeature);
      return;
    }

    this.dynamicModalService.abrirModal(recuperaCTDepuradoFeature);
  }

  public obterCompensacoes(imovelRural: CcitrImoveisDoContribuinteDTO): void {

    this.setaValorCPFouCNPJContribuinte(imovelRural.nirf);

    let parametros = {
      nirf: imovelRural.nirf,
      cnpjContribuinte: this.cnpjContribuinte,
      cpfContribuinte: this.cpfContribuinte,
      indicadorCnpjBasico: this.parametros.indicadorCnpjBasico,
      tipoLancamento: this.parametros.tipoLancamento,
      dataInicial: '',
      dataFinal: '',
      numeroProcessoRestRess: '',
      nueroCreditoRess: '',
      numeroCt: '',
      numeroComponente: '',
      atendimento: this.atendimento
    } as ParametrosConsultaCompensacoesCcitr;

    const consultaCompensacoesFeature = {
      id: `ConsultaCCITR_Compensacoes_${imovelRural.nirf}`,
      title: 'Pagamentos - Consultar',
      class: CcitrConsultaCompensacoesComponent,
      parametros: parametros
    } as FeatureAction;

    if (this.atendimento) {
      consultaCompensacoesFeature.active = true;
      consultaCompensacoesFeature.rolesAllowed = [];
      consultaCompensacoesFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(consultaCompensacoesFeature);
      return;
    }

    this.dynamicModalService.abrirModal(consultaCompensacoesFeature);
  }

  consultaCreditos(imovelRural: CcitrImoveisDoContribuinteDTO): void {

    this.setaValorCPFouCNPJContribuinte(imovelRural.nirf);

    let parametros = {
      nirf: imovelRural.nirf,
      cnpjContribuinte: this.cnpjContribuinte,
      cpfContribuinte: this.cpfContribuinte,
      indicadorCnpjBasico: this.parametros.indicadorCnpjBasico,
      tipoLancamento: this.parametros.tipoLancamento,
      exercicioInicial: this.parametros.exercicioInicial,
      exercicioFinal: this.parametros.exercicioFinal,
      vencidosAVencer: this.parametros.vencidosAVencer,
      atendimento: this.atendimento
    } as ParametrosPesquisaCcitr;

    const consultaCreditosFeature = {
      id: `ConsultaCCITR_Compensacoes_${imovelRural.nirf}`,
      title: 'Pagamentos - Consultar',
      class: CcitrConsultaCreditosComponent,
      parametros: parametros
    } as FeatureAction;

    if (this.atendimento) {
      consultaCreditosFeature.active = true;
      consultaCreditosFeature.rolesAllowed = [];
      consultaCreditosFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(consultaCreditosFeature);
      return;
    }

    this.dynamicModalService.abrirModal(consultaCreditosFeature);
  }

  exibirPagamentosDARF(imovelRural: CcitrImoveisDoContribuinteDTO): void {

    this.setaValorCPFouCNPJContribuinte(imovelRural.nirf);

    let parametrosPesquisa = {
      cnpjContribuinte: this.cnpjContribuinte,
      cpfContribuinte: this.cpfContribuinte,
      exercicioInicial: this.parametros.exercicioInicial,
      exercicioFinal: this.parametros.exercicioFinal,
      tipoLancamento: this.parametros.tipoLancamento,
      indicadorCnpjBasico: this.parametros.indicadorCnpjBasico,
      nirf: imovelRural.nirf,
      atendimento: this.atendimento
    } as ParametrosPesquisaCcitr;

    const consultaPagamentosDARFFeature = {
      id: `ConsultaCCITR_PagamentoDARF_${imovelRural.nirf}`,
      title: 'Pagamentos - Consultar',
      class: CcitrPagamentosDarfComponent,
      parametros: { ...parametrosPesquisa, paraAlocacao: false }
    } as FeatureAction;

    if (this.atendimento) {
      consultaPagamentosDARFFeature.active = true;
      consultaPagamentosDARFFeature.rolesAllowed = [];
      consultaPagamentosDARFFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(consultaPagamentosDARFFeature);
      return;
    }

    this.dynamicModalService.abrirModal(consultaPagamentosDARFFeature);
  }

  consultarDetalheCAFIR(imovelRural: CcitrImoveisDoContribuinteDTO): void {
    const detalheImovelFeature = {
      id: `CONSULTA_DETALHE_IMOVEL_RURAL_${imovelRural.nirf}`,
      class: DetalheImovelRuralComponent,
      title: 'Consulta Detalhe Imóvel Rural',
      parametros: {
        ni: imovelRural.niProprietario,
        cib: imovelRural.nirf,
        isModal: true
      }
    } as FeatureAction;

    this.dynamicModalService.abrirModal(detalheImovelFeature);
  }
}