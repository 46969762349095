import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Subscription } from "rxjs";
import { ParametrosConsultaEventosCT } from "src/app/cobranca/models/parametros-pesquisa";
import { DynamicModalRequestService } from "src/app/shared/dynamic-modal/dynamic-modal-request.service";
import { IDynamicPanel, OptionalParams } from "src/app/shared/dynamic-panel/panel-interface";
import { MenuFeatureActionsService } from "src/app/shared/menu-feature-actions/menu-feature-actions.service";
import { FeatureAction, TipoMenu } from "src/app/shared/model/feature";
import { FormatCpfPipe } from "src/app/shared/pipes/formata-cpf.pipe";
import { ScopedMessageService } from "src/app/shared/services/scoped-message.service";
import { EventosCreditoTributario } from "../eventos-credito-tributario/eventos-credito-tributario.component";

@Component({
  templateUrl: './eventos-credito-tributario-filtros.component.html',
  styleUrls: ['./eventos-credito-tributario-filtros.component.css'],
  providers: [ScopedMessageService]
})
export class EventosCreditoTributarioFiltros implements IDynamicPanel, OnInit, OnDestroy {

  cpfCtrl: UntypedFormControl;
  dataInicialCtrl: UntypedFormControl;
  dataFinalCtrl: UntypedFormControl;

  filtrosForm: UntypedFormGroup;

  filtroColapsado = false;
  exibeResultado = false;

  parametros: ParametrosConsultaEventosCT;

  cpfContribuinte: string;
  numeroCT: string;

  initError: Error;
  titulo: string;

  ni: string;

  featureEventSub: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dynamicModalRequest: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService) { }

  init(ni: string, params?: OptionalParams): void {
    this.ni = ni;
    this.cpfContribuinte = params.cpfContribuinte as string;
    this.numeroCT = params.numeroCT as string;
    this.titulo = `CCPF - Consultar Eventos do CT: ${this.numeroCT} - CPF: ${FormatCpfPipe.formatCPF(this.cpfContribuinte)}`;

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        const feature = featureEvent.featureAction;
        if (featureEvent.active == false && feature.id == 'CcpfConsultar' && feature.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharConsultaEventosCT();
          this.featureEventSub?.unsubscribe();
        }
      }
    );
  }

  ngOnInit(): void {
    this.buildForm();
    this.addValidators();
    this.subscribeValueChanges();
    this.onReset();
  }

  ngOnDestroy(): void {
    this.featureEventSub?.unsubscribe();
  }

  private fecharConsultaEventosCT(): void {
    const feature = {
      id: `CCPFEventosCTFiltros_credito_${this.numeroCT}`,
      title: 'CCPF - Eventos do CT',
      class: EventosCreditoTributarioFiltros,
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO
    } as FeatureAction;

    this.menuFeatureActionsService.deactivate(feature);
  }

  private buildForm(): void {
    this.cpfCtrl = new UntypedFormControl('');
    this.dataInicialCtrl = new UntypedFormControl('');
    this.dataFinalCtrl = new UntypedFormControl('');

    this.filtrosForm = this.formBuilder.group({
      cpf: this.cpfCtrl,
      dataInicial: this.dataInicialCtrl,
      dataFinal: this.dataFinalCtrl
    });
  }

  private addValidators(): void {
    this.dataInicialCtrl.addValidators(this.dataInicialValidator());
    this.dataFinalCtrl.addValidators(this.dataFinalValidator());
  }

  private subscribeValueChanges(): void {
    this.dataInicialCtrl.valueChanges.subscribe(value => {
      this.dataFinalCtrl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
    this.dataFinalCtrl.valueChanges.subscribe(value => {
      this.dataInicialCtrl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
  }

  onReset(): void {
    this.filtrosForm.reset();
    this.cpfCtrl.setValue(this.cpfContribuinte);
    this.cpfCtrl.disable();
  }

  private dataInicialValidator(): ValidatorFn {
    return (_control: AbstractControl): ValidationErrors | null => {
      if (!this.dataFinalCtrl.value) {
        return null;
      }
      if (!this.dataInicialCtrl.value) {
        return { data_inicial_nao_informada: true };
      }
      if (this.dataInicialCtrl.value >= this.dataFinalCtrl.value) {
        return { data_inicial_maior_data_final: true };
      }
      return null;
    };
  }

  private dataFinalValidator(): ValidatorFn {
    return (_control: AbstractControl): ValidationErrors | null => {
      if (!this.dataInicialCtrl.value) {
        return null;
      }
      if (!this.dataFinalCtrl.value) {
        return { data_final_nao_informada: true };
      }
      if (this.dataInicialCtrl.value >= this.dataFinalCtrl.value) {
        return { data_inicial_maior_data_final: true };
      }
      return null;
    };
  }

  onSubmit(): void {
    this.parametros = {
      cpfContribuinte: this.cpfContribuinte,
      dataInicial: this.dataInicialCtrl.value,
      dataFinal: this.dataFinalCtrl.value,
      numeroCT: this.numeroCT
    };

    if (this.ni) {
      this.exibeResultado = true;
      this.filtroColapsado = true;
      return;
    }

    const feature = {
      id: `CCPFConsultaEventos_credito_${this.numeroCT}`,
      title: 'CCPF - Consultar Eventos do CT',
      class: EventosCreditoTributario,
      parametros: this.parametros
    } as FeatureAction;

    this.dynamicModalRequest.abrirModal(feature);
  }

  colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

  esconderResultado(): void {
    this.exibeResultado = false;
    this.filtroColapsado = false;
  }
}
