import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, SimpleChange, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@serpro/ngx-suite-rfb';
import { isEmpty, isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { ComponenteCcitr, CreditoTributarioCcitr } from 'src/app/cobranca/models/credito-tributario-ccitr';
import { ParametrosPesquisaCcitr } from 'src/app/cobranca/models/parametros-pesquisa-ccitr';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CCITRService } from '../../services/ccitr.service';
import { ParametroDemonstrativoQuotasCCITR } from '../consulta-creditos/model/parametro-demonstrativo-quotas-ccitr';
import { isWarning } from './../../../../models/erro-padrao';
import { CcitrBase } from './../../base/CcitrBase.component';

@Component({
  selector: 'via-ccitr-demonstrativo-quotas',
  templateUrl: './ccitr-demonstrativo-quotas.component.html',
  styleUrls: ['./ccitr-demonstrativo-quotas.component.css','../../base/ccitr.css'],
  providers: [ScopedMessageService]
})
export class CCITRDemonstrativoQuotasComponent extends CcitrBase implements IDynamicPanel, OnDestroy {

  @Input() parametros: ParametroDemonstrativoQuotasCCITR;
  @Input() credito: CreditoTributarioCcitr;
  @Input() parametrosPesquisa: ParametrosPesquisaCcitr;

  titulo: string;

  componentes: ComponenteCcitr[];

  formGroup: UntypedFormGroup;
  qtdQuotasName = 'qtdQuotas';

  scopeId: string;
  initError: Error;

  featureEventSub: Subscription;

  constructor(
    private messageService: ScopedMessageService,
    private ccitrService: CCITRService,
    private formBuilder: UntypedFormBuilder,
    private loadingService: LoadingService,
    private menuFeatureActionsService: MenuFeatureActionsService) {
      super();
    }

  init(_ni: string, params?: OptionalParams): void {

    super.init(_ni, params);
    this.parametros = params as ParametroDemonstrativoQuotasCCITR;
    this.parametrosPesquisa = params?.parametrosPesquisa;

    this.credito = params?.credito;
    this.componentes = this.credito?.componentes;

    this.titulo = `CCITR - Alterar Demonstrativo de Quotas do CT: ${this.credito?.numeroCt}`;

    this.formGroup = this.formBuilder.group({
      qtdQuotas: ['', [Validators.required]]
    });

    this.scopeId = this.messageService.scopeId;

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        const feature = featureEvent.featureAction;
        if (featureEvent.active == false && feature.id == 'CcitrConsultar' && feature.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharDemonstrativoQuotas();
          this.featureEventSub?.unsubscribe();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.featureEventSub?.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }


  onAlterarQtdQuotas(): void {
    this.loadingService.show();
    this.ccitrService.alterarQtdQuotas(this.parametros.parametros.cnpjContribuinte||this.parametros.parametros.cpfContribuinte, this.parametros.parametros.nirf, this.credito.numeroCt, this.qtdQuotas)
      .subscribe(
        _sucesso => {
          this.formGroup.reset();
          this.messageService.showInfo('Quotas do Crédito Tributário alteradas com sucesso');
          this.ccitrService.atualizarListagemCreditos(this.parametros.parametros);
        },
        (err: HttpErrorResponse) => {
          if (isWarning(err.error)) {
            this.messageService.showWarning(err.error.message);
            return null;
          } else {
            this.messageService.showErrorException('Ocorreu um erro ao alterar a Quantidade de Quotas do Crédito Tributário', err);
          }
        }
        ).add(() => {
        this.loadingService.hide();
      });
  }

  private get qtdQuotas(): number {
    return this.formGroup.get(this.qtdQuotasName).value as number;
  }

  ngOnInit(): void {
    this.ccitrService.notificarAtualizacaoListaCreditosEmitter.subscribe(creditos => {
      if (!isEmpty(creditos) && this.credito) {
        const ct = this.credito.numeroCt;
        this.credito = creditos.find(credito => isEqual(credito.numeroCt, ct));
        this.componentes = this.credito?.componentes;
        this.loadingService.hide();
      }
    });
  }

  private fecharDemonstrativoQuotas(): void {
    const feature = {
      id: `CCITRDemonstrativoQuotas_credito_${this.credito.numeroCt}`,
      title: 'CCITR - Alterar Demonstrativo de Quotas',
      class: CCITRDemonstrativoQuotasComponent,
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO
    } as FeatureAction;

    this.menuFeatureActionsService.deactivate(feature);
  }
}
