<suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
  <div class="container-fluid container-filtros">
    <div class="container-fluid">
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">

        <div class="row">
          <div class="col-md-6 col-data-arrecadacao">
            <label class="control-label" for="nirfName">CIB</label>
            <div class="form-group" [suiteRfbHasError]="cibCtrl">
              <input class="form-control" type="text" [formControlName]="cibName" suiteRfbCIBAlphaNum maxlength="11">
            </div>
          </div>
          <div class="col-md-6 col-data-arrecadacao">
            <label class="control-label" for="cpfName">CPF</label>
            <div class="form-group" [suiteRfbHasError]="cpfCtrl">
              <input class="form-control" type="text" [formControlName]="cpfName"
                      autocomplete="off" suiteRfbInputMask="999.999.999-99" suiteRfbValidCpf>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-data-arrecadacao">
            <label class="control-label" for="cnpjName">CNPJ</label>
            <div class="form-group" [suiteRfbHasError]="cnpjCtrl">
              <input class="form-control" type="text" [formControlName]="cnpjName"
                      autocomplete="off" suiteRfbInputMask="99.999.999/9999-99" suiteRfbValidCnpj>
            </div>
          </div>
          <div class="col-md-6 col-faixa-valor">
            <label class="control-label"  for="cnpjBasicoName">CNPJ Básico</label>
            <div class="form-group" [suiteRfbHasError]="cnpjBasicoCtrl">
              <input class="form-control" type="text" [formControlName]="cnpjBasicoName"
                      autocomplete="off" suiteRfbInputMask="99.999.999">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="radio" [formControlName]="tipoLancamentoName" [value]="criterioSelecaoTodos">
                  <span>Todos os créditos tributários</span>
                </div>
                <div class="form-group">
                  <input type="radio" [formControlName]="tipoLancamentoName" [value]="criterioSelecaoMaed">
                  <span>Somente MAED</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12 col-data-arrecadacao">
                <div class="form-inline">
                  <div class="form-group" [suiteRfbHasError]="cpfCtrl">
                    <div class="form-group">
                      <input type="checkbox" [formControlName]="criterioMarcacaoName" [value]="criterioMarcacaoVencimento">
                      <span>Com CT em Aberto ou a Vencer</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-faixa-valor">
                <label class="control-label">Exercício</label>
                <div class="form-inline">
                  <input class="form-control input-exercicio" type="text" autocomplete="off" maxlength="4"
                    [formControlName]="exercicioDeName" suiteRfbInputMask="9999" NumbersOnlyDirective>
                  <label class="control-label faixa-valor-label-separador" style="margin: 0 5px;">a</label>
                  <input class="form-control input-exercicio" type="text" autocomplete="off" maxlength="4"
                    [formControlName]="exercicioAteName" suiteRfbInputMask="9999" NumbersOnlyDirective>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" style="margin-top: 25px;">
            <div class="form-group">
              <div class="text-center">
                <button type="button" (click)="onReset()" class="btn btn-default" [disabled]="!formGroup.dirty">Limpar</button>
                <button type="submit" #buscar class="btn btn-primary" [disabled]="formGroup.invalid || desabilitaBotaoBuscar">Buscar</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</suite-rfb-collapse>
<via-ccitr-imoveis-do-contribuinte *ngIf="exibeResultado" [parametros]="parametros" [atendimento]="true" (onError)="esconderResultado()"></via-ccitr-imoveis-do-contribuinte>
