import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PainelAtendimentosComponent } from './workplace/components/painel-atendimentos/painel-atendimentos.component';

const routes: Routes = [
  {
    path: '',
    component: PainelAtendimentosComponent,
    data: { breadcrumb: 'Página Inicial' },
  },
  {
    path: '**',
    redirectTo: '', 
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
