import { isWarning } from './../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, EventEmitter, Output, QueryList, ViewChildren, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { isEmpty } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { LogService } from 'src/app/shared/services/log.service';
import { FiltrosPesquisaCcitrComponent } from '../filtros-pesquisa-ccitr/filtros-pesquisa-ccitr.component';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { ParametrosPesquisaCcitr } from '../../../../models/parametros-pesquisa-ccitr';
import { PagamentoTDA} from '../../../../models/pagamento-tda';
import { ComponentePagamentoTDA} from '../../../../models/componente-pagamento-tda';
import { CCITRService } from '../../services/ccitr.service';
import { FormatCibPipe } from 'src/app/shared/pipes/formata-cib.pipe';
import { CcitrBase } from '../../base/CcitrBase.component';
import { CONSTANTS } from '../../constants';

@Component({
  selector: 'via-consulta-pagamentos-tda',
  templateUrl: './consulta-pagamentos-tda.component.html',
  styleUrls: ['./consulta-pagamentos-tda.component.css','../../base/ccitr.css'],
  providers: [ScopedMessageService]
})
export class ConsultaPagamentosTDAComponent extends CcitrBase implements IDynamicPanel, OnChanges, OnDestroy, OnInit {

  @Input() parametros: ParametrosPesquisaCcitr;
  @Input() parentScopeId: string;
  @Output() onError: EventEmitter<void> = new EventEmitter();
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pagamentosTDA: PagamentoTDA[];

  stopLoading = false;
  initError: Error;
  scopeId: string;
  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
  showCloseModal = true;
  cpf: string;

  filtroColapsado = false;

  updateSubscription: Subscription;

  constructor(
    private bsModalRef: BsModalRef,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private dynamicModalRequest: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private cCITRService: CCITRService ) {
    super();
  }


  public init(ni: string, params?: OptionalParams): void {
    super.init(ni, params);
    this.showCloseModal = false;
    this.parametros = params as ParametrosPesquisaCcitr;
    this.recarregar();
  }

  ngOnInit(): void {
    this.initError = null;
    this.scopeId = this.messageService.scopeId;
    this.cpf = this.parametros.cpfContribuinte;
    this.formataTitulo(this.parametros, 'CCITR - Consultar Pagamentos TDA');
  }

  onFecharModal(): void {
    this.bsModalRef?.hide();
  }

  private pesquisarPagamentos(): void {

    let pagamentosObservable: Observable<PagamentoTDA[]>;

    this.cCITRService.consultarPagamentosTDA(this.parametros)
      .subscribe(
        resp => {
          try{
              this.pagamentosTDA = resp;
              if ( this.pagamentosTDA && isEmpty(this.pagamentosTDA)) {
                this.stopLoading = true;
                this.messageService.showInfo('Nenhum resultado encontrado');
              }
            } catch (e) {
              this.initError = e;
              this.logService.logError('ConsultaPagamentosComponent', 'consultarPagamentosTDA', e);
              this.messageService.showErrorException('Ocorreu um erro ao executar a pesquisa', e);
            }
        },
        (err: HttpErrorResponse) => {
          if (isWarning(err.error)) {
            this.messageService.showWarning(err.error.message);
            this.stopLoading = true;
            return null;
          } else {
            this.initError = err;
            this.onError.emit();
            this.messageService.showErrorException("Ocorreu um erro ao consultar CCITR Pagamentos TDA ", err);
            return throwError(err);
          }
        }
      );
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if(this.titulo) {
      this.recarregar();
    }
  }

  public ngOnDestroy(): void {
    if(this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  public recarregar(): void {
    this.initError = null;
    //this.pagamentosTDA = null;
    this.pesquisarPagamentos();
  }

  public esconderResultado(): void {
    this.filtroColapsado = false;
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }


}
