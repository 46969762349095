import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { SuiteRfbPaginationModule, SuiteRfbCollapseModule, SuiteRfbTableModule, SuiteRfbPopoverModule } from '@serpro/ngx-suite-rfb';
import { ParcelamentoPaexPFAtivoComponent,ParcelamentoPaexPFSuspensoComponent,ParcelamentoPaexPJAtivoComponent,ParcelamentoPaexPJSuspensoComponent } from './parcelamento-paex.component';
import { ParcelamentoPaexComponent } from './parcelamento-paex.component';
import { ParcelamentoPaexService } from './parcelamento-paex.service';
import { ParcelamentoPaexTableComponent } from './table/parcelamento-paex-table.component';

@NgModule( {
    declarations: [
        ParcelamentoPaexTableComponent,
        ParcelamentoPaexComponent,
        ParcelamentoPaexPFAtivoComponent,
        ParcelamentoPaexPFSuspensoComponent,
        ParcelamentoPaexPJAtivoComponent,
        ParcelamentoPaexPJSuspensoComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbPopoverModule
    ],
    providers: [
        ParcelamentoPaexService
    ]
})
export class ParcelamentoPaexModule {}
