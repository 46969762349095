import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbPopoverModule, SuiteRfbTableModule, SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { ParcelamentoDividaComponent, ParcelamentoDividaPFAtivoComponent, ParcelamentoDividaPFSuspensoComponent, ParcelamentoDividaPJAtivoComponent, ParcelamentoDividaPJSuspensoComponent } from './parcelamento-divida.component';
import { ParcelamentoDividaService } from './parcelamento-divida.service';
import { ParcelamentoDividaTableComponent } from './table/parcelamento-divida-table.component';

@NgModule({
    declarations: [
        ParcelamentoDividaTableComponent,
        ParcelamentoDividaComponent,
        ParcelamentoDividaPFAtivoComponent,
        ParcelamentoDividaPFSuspensoComponent,
        ParcelamentoDividaPJAtivoComponent,
        ParcelamentoDividaPJSuspensoComponent
    ],
    imports: [
        CommonModule,
        NgxMaskDirective, NgxMaskPipe,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbPopoverModule,
        SuiteRfbToggleModule
    ],
    providers: [
        ParcelamentoDividaService,
        provideNgxMask()
    ]
})
export class ParcelamentoDividaModule { }
