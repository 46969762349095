import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertMessage, AlertMessageService } from '@serpro/ngx-suite-rfb';
import { ErrorVia } from 'src/app/model/generic-response';

//
// Implementação inicialmente copiada de MessageService, que é global, fornecida pelo injetor raiz
// TODO: avaliar o uso de uma classe base abstrata para os dois serviços, para evitar duplicação de código comum 
//
@Injectable()
export class ScopedMessageService {

    public readonly scopeId = (Math.random() + 1).toString(36).substring(2);

    private messages = [] as AlertMessage[];

    constructor(protected _alertMessageService: AlertMessageService) {}

    ERROR = "error";
    WARNING = "warning";
    INFO = "info";

    public dismissAllMessages() {
        // console.log('dismissAllMessages');
        // limpa todas as mensagens
        this._alertMessageService.dismissAll();
        // se há mensagens prioritárias mostra
        const prioritaryError: {title: string, msg: string} = this.getPrioritaryError();
        if (prioritaryError) {
          this.showError(prioritaryError.msg, prioritaryError.title);
          // limpa
          this.clearPrioritaryError();
        }
    }

    public dismissMessages() {
        let m = this.messages.pop();
        while(m) {
            this._alertMessageService.dismiss(m);
            m = this.messages.pop();
        }

        // se há mensagens prioritárias mostra
        const prioritaryError: {title: string, msg: string} = this.getPrioritaryError();
        if (prioritaryError) {
            this.showError(prioritaryError.msg, prioritaryError.title);
          // limpa
          this.clearPrioritaryError();
        }
    }

    protected setPrioritaryError(title: string, msg: string) {
    //   console.log('setPrioritaryError', title + ': ' + msg);
      localStorage.setItem('prioritary-errors', title + '#' + (msg || ''));
    }

    protected getPrioritaryError(): {title: string, msg: string} {
      const prioritaryError = localStorage.getItem('prioritary-errors');
      let error = null;
      try {
        error = {title: prioritaryError.split('#')[0], msg: prioritaryError.split('#')[1]};
      } catch (e) { }
      return error;
    }

    protected clearPrioritaryError() {
      localStorage.removeItem('prioritary-errors');
    }

    public showWarning(msg: string, title: string = null) {
        if (title) {
            this.messages.push(this._alertMessageService.warn(title, msg, undefined, undefined, this.scopeId));
        } else {
            this.messages.push(this._alertMessageService.warn(null, msg, undefined, undefined, this.scopeId));
        }
    }

    public showInfo(msg: string, title: string = null) {
        // console.log('showInfo: ', title, msg);
        // this._loaderService.hide();
        if (title) {
            this.messages.push(this._alertMessageService.info(title, msg, undefined, undefined, this.scopeId));
        } else {
            this.messages.push(this._alertMessageService.info(null, msg, undefined, undefined, this.scopeId));
        }
    }



    public showError(msg: string, title: string = null) {
        if (title) {
            this.messages.push(this._alertMessageService.error(title, msg, undefined, undefined, this.scopeId));
        } else {
            this.messages.push(this._alertMessageService.error(null, msg, undefined, undefined, this.scopeId));
        }
    }
    
    public showErrorExceptionNI(ni: string, title: string, exception: Error, delay: boolean = false): void {
        this.showErrorException('['+ni+'] '+title, exception, delay);
    }

    public showErrorException(title: string, exception: Error, delay: boolean = false): void {
        const excecao = this.extractMessageFromError(exception);
        this.showErrorTitleException(title, excecao.message, excecao.exception, excecao.type, delay);
    }

    protected showErrorTitleException(title: string, msg: string, detail?: string, type?: string, delay?: boolean): void {
        let options = delay ? {delay: 15000} : null;
        let details = detail ? [detail] : null;
        if (type == this.INFO) {
            this.messages.push(this._alertMessageService.info(title, msg, details, options, this.scopeId));
        } else if (type == this.WARNING) {
            this.messages.push(this._alertMessageService.warn(title, msg, details, options, this.scopeId));
        } else {
            this.messages.push(this._alertMessageService.error(title, msg, details, options, this.scopeId));
        }
    }


    protected extractMessageFromError(error: any): {message: string, exception: string, type: any} {
        // mostra o erro exceção de javascript no console, se houver
        console.log('extractMessageFromError: ', error);
        let type = null;

        // recupera a mensagem se for Error
        let message = null;
        let detail = null;        
        if (error.message.includes('feche todos os navegadores')) {
            message = 'A sessão do usuário expirou.' +
            'Será necessário que você realize um novo login no CamWeb.';
            return
        }

        // se expirou a sessão do token de autenticação
        switch (error.status) {
            case 0: {
              if (error.message && error.message.indexOf('unknown url') >= 0) {
                // trata o caso de backend offline
                message = error.message;
              } else if (error.message && error.message.indexOf('Http failure response for') >= 0) {
                message = 'Ocorreu uma falha na comunicação com o servidor, que impossibilitou a execução da ação.';
              } else {
                // title = 'Sessão Expirada';
                // message = 'A sessão atual expirou! Favor retornar à página de login para nova autenticação.';
                // redireciona para a suite RFB
                setTimeout(() => {
                    this.clearPrioritaryError();
                    this.redirectToTimeouUrl();
                }, 100);
              }
              type = this.ERROR;
              break;
            }
            // case 500: {
            //     [message, detail] = this.extractDetails(error);
            //     break;
            // }
            // case 422: {
            //     [message, detail] = this.extractDetails(error);
            //     break;
            // }
            case 503: {
                [message, detail] = this.extractDetails(error);
                message = 'O servidor atualmente é incapaz de lidar com o pedido devido a uma sobrecarga temporária ou manutenção do servidor. ' +
                'Por favor, aguarde alguns instantes e tente novamente.';
                type = this.ERROR;
                break;
            }
            case 403: {
                [message, detail] = this.extractDetails(error);
                message = 'A sessão do usuário expirou.' +
                'Será necessário que você realize um novo login no CamWeb.';
                type = this.WARNING;
                break;
            }
            case 404: {
                [message, detail] = this.extractDetails(error);
                message = 'A sessão do usuário expirou.' +
                'Será necessário que você realize um novo login no CamWeb.';
                type = this.WARNING;
                break;
            }
            case 412: {
                [message, detail] = this.extractDetails(error);
                message = 'Falha de pré-condição.' +
                'Os dados enviados ao servidor estão incorretos.';
                type = this.ERROR;
                break;
            }
            default: {
                if (error instanceof HttpErrorResponse) {
                    if (error.error && error.error.message){
                        message = error.error.message;
                    }
                    if (error.error && typeof error.error == 'string'){
                        message = JSON.parse(error.error).message;
                    }
                    if(!message){
                        message = error.message;
                    }
                } else {
                  message = JSON.stringify(error);
                }
                type = this.ERROR;
                break;
            }
        }
        
        // if (!detail && error.status !== 0 && error.status !== 400 && error.status !== 404 &&
        //    error.status !== 412) {
        //   detail = `Status [${error.status}] ${detail}`;
        // }

        return {message, exception: detail, type};
    }

    protected redirectToTimeouUrl() {
        //window.location.href = environment.TimeoutUrl; // quebra a verificação de erro genérico
    }

    private extractDetails(error: any): [string, string] {
        let detail = '';
        let message = '';
        try {
             // console.log('#error:', error.error);
             const msg = error.error.message;
             // console.log('#message:', msg);
             const str = msg.match(/\[.*\]/);
             // console.log('extractDetails str', str);
             const err = JSON.parse(str)[0];
             // console.log('error obj after parse', err);
             if (err.msg) {
                 message = err.msg;
             }
             else if (err.message) {
               message = err.message;
                }  else if (err.error) {
                message = err.error;
                //  console.log('#err:', err.erros);
                if (err.erros && err.erros.length > 0) {
                     if (err.erros[0].erro) {
                         detail = err.erros[0].erro;
                     } else if (err.erros[0].id) {
                         detail = err.erros[0].id;
                     }
                    //  else if (err.erros[0].id_atendente) {
                    //      detail = err.erros[0].id_atendente;
                    //  }
                 }
             }
         } catch (e) {
             message = 'Erro inesperado! Tente efetuar a operação novamente. Se o problema persistir, acione a Central de Serviços.';             
         }

        return [message, detail];
    }

    processarErros( errors: ErrorVia[],title? : string)
    {
     console.log(errors);
     
     if (errors.length > 0) {
         errors.forEach(error => {
             switch (error.severidade) {
                 case 'ALTA':                                 
                if (error.detalhe) {
                    this.messages.push(this._alertMessageService.error(title, error.message, [error.detalhe], undefined, this.scopeId));
                } else {
                    this.showError(error.message,title);
                }                                 
                   break;
                 case 'MEDIA':
                   this.showWarning(error.message,title);
                   break;
                 case 'BAIXA':
                   this.showInfo(error.message,title);
                   break;
                 default:
                   this.showInfo(error.message,title);             
                   break;
               }

         })
       }
    }
}
