import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LoadingService } from '@serpro/ngx-suite-rfb';
import { isEmpty, isEqual, isNull } from 'lodash';
import { Subscription } from 'rxjs';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CreditoTributario } from '../../../../models/credito-tributario';
import { MotivoTransferencia } from '../../../../models/motivo-transferencia';
import { ParametrosPesquisaCreditosTributarios, ParametroTransferenciaCredito } from '../../../../models/parametros-pesquisa';
import { CCPFService } from '../../services/ccpf.service';

@Component({
  templateUrl: 'transfere-credito-tributario.component.html',
  styleUrls: ['transfere-credito-tributario.component.css'],
  providers: [ScopedMessageService]
})
export class CcpfTransfereCreditoComponent implements OnInit, OnDestroy, IDynamicPanel {

  @Input() atendimento: boolean = false;
  @Input() parametros: ParametroTransferenciaCredito;
  @Input() parametrosPesquisa: ParametrosPesquisaCreditosTributarios;
  @Output() onError: EventEmitter<void> = new EventEmitter();

  private readonly MOTIVO_TRANSFERENCIA_OUTROS = '9';
  private readonly PERDCOMP = 'A';

  titulo: string = 'CCPF - Transferir CT';
  scopeId: string;
  initError: Error;

  formGroup: UntypedFormGroup;
  motivoTransferenciaCtrl: UntypedFormControl;
  numeroProcessoCtrl: UntypedFormControl;
  motivoOutrosCtrl: UntypedFormControl;

  credito: CreditoTributario;
  motivoTransferenciaName: string = 'motivoTransferencia';
  numeroProcessoName: string = 'numeroProcesso';
  motivoOutrosName = 'motivoOutros';
  exibirMotivo: boolean = false;
  posicaoListaMotivos: string = 'top';

  motivosTransferencia: MotivoTransferencia[];

  cpfContribuinte: string;

  executouObterDados = false;
  filtroColapsado = false;
  stopLoading = false;

  featureEventSub: Subscription;
  updateSubscription: Subscription;

  constructor(
    private messageService: ScopedMessageService,
    private ccpfService: CCPFService,
    private formBuilder: UntypedFormBuilder,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private loadingService: LoadingService) { }

  public init(_ni: string, params?: OptionalParams): void {
    this.parametros = params as ParametroTransferenciaCredito;
    this.cpfContribuinte = this.parametros.cpfContribuinte;
    this.credito = this.parametros.creditoTributario;
    this.atendimento = this.parametros.atendimento ?? this.atendimento;
    this.parametrosPesquisa = this.parametros.parametrosPesquisa;
    this.titulo = `${this.titulo} - CT: ${this.credito.ct}`;
    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        // se a chave de identificação da feature for a mesma
        if (featureEvent.active == false && featureEvent.featureAction.id == 'CcpfConsultar' && featureEvent.featureAction.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharFeature();
          this.featureEventSub.unsubscribe();
        }
      });
  }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
    this.buildForm();
    this.addValidators();
    this.subscribeValueChanges();
    this.obterMotivosTransferencia();
    this.formGroup.reset();
    this.configurarMotivo(false);

    this.updateSubscription = this.ccpfService.notificarAtualizacaoListaCreditosEmitter.subscribe(creditos => {
      if (!isEmpty(creditos) && this.credito) {
        const ct = this.credito.ct;
        this.credito = creditos.find(credito => isEqual(credito.ct, ct));
      }
    });
  }

  buildForm(): void {
    this.formGroup = this.formBuilder.group({
      motivoTransferencia: this.motivoTransferenciaCtrl = new UntypedFormControl(''),
      numeroProcesso: this.numeroProcessoCtrl = new UntypedFormControl(''),
      motivoOutros: this.motivoOutrosCtrl = new UntypedFormControl(''),
    });
  }

  addValidators(): void {
    this.motivoTransferenciaCtrl.addValidators([Validators.required]);
    this.numeroProcessoCtrl.addValidators(this.numeroProcessoValidator());
    this.motivoOutrosCtrl.addValidators([Validators.required, Validators.maxLength(29)]);
  }

  subscribeValueChanges(): void {
    this.motivoTransferenciaCtrl.valueChanges.subscribe(value => {
      this.numeroProcessoCtrl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
  }

  public get numeroProcesso(): string {
    return this.formGroup.get(this.numeroProcessoName).value as string;
  }

  public set numeroProcesso(numero: string) {
    this.formGroup.patchValue({ numeroProcesso: numero });
  }

  public get motivoTransferencia(): MotivoTransferencia {
    return this.formGroup.get(this.motivoTransferenciaName).value as MotivoTransferencia;
  }

  public set motivoTransferencia(motivo: MotivoTransferencia) {
    this.formGroup.patchValue({ motivoTransferencia: motivo });
  }

  public get motivoOutros(): string {
    return this.formGroup.get(this.motivoOutrosName).value as string;
  }

  public set motivoOutros(motivo: string) {
    this.formGroup.patchValue({ motivoOutros: motivo });
  }

  numeroProcessoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.formGroup.get(this.numeroProcessoName).value) {
        return null;
      }
      if (this.motivoTransferencia.codigo == this.PERDCOMP && (this.numeroProcesso.length != 24)) {
        return { numero_processo_invalido: true };
      }
      if (this.motivoTransferencia.codigo != this.PERDCOMP && !(this.numeroProcesso.length == 15 || this.numeroProcesso.length == 17)) {
        return { numero_processo_invalido: true };
      }
      return null;
    };
  }

  public configurarMotivo(evento: boolean): void {
    if (!isNull(this.motivoTransferencia) && this.motivoTransferencia.codigo === this.MOTIVO_TRANSFERENCIA_OUTROS) {
      this.formGroup.get(this.motivoOutrosName).enable();
    } else {
      this.formGroup.get(this.motivoOutrosName).disable();
      this.formGroup.patchValue({ motivoOutros: "" });
    }
  }

  onReset() {
    this.formGroup.reset();
    this.messageService.dismissMessages();
  }

  onSubmit(): void {
    this.messageService.dismissMessages();
    this.loadingService.show();
    this.ccpfService.transferirCredito(
      this.cpfContribuinte,
      this.credito.ct,
      this.motivoTransferencia.codigo,
      this.numeroProcesso,
      this.motivoOutros)
      .subscribe(
        _sucesso => {
          this.messageService.showInfo('Crédito Tributário transferido com sucesso');
          this.ccpfService.atualizarListagemCreditos(this.parametrosPesquisa);
          this.loadingService.hide();
        },
        error => {
          this.onError.emit();
          this.loadingService.hide();
          this.messageService.showErrorException('Ocorreu um erro ao transferir o Crédito Tributário', error);
        }
      );
  }

  public colapsar(collapsed: boolean): void {
    this.filtroColapsado = collapsed;
  }

  private obterMotivosTransferencia(): void {
    this.ccpfService.recuperarMotivosTransferenciaCT()
      .subscribe(
        response => {
          this.motivosTransferencia = response;
          if (this.motivosTransferencia && isEmpty(this.motivosTransferencia)) {
            this.messageService.showError('Ocorreu um erro ao recuperar os motivos de transferência.');
            this.stopLoading = true;
          } else {
            this.executouObterDados = true;
          }
        },
        error => {
          this.messageService.showErrorException('Ocorreu um erro ao recuperar os motivos de transferência.', error);
          this.initError = error;
        }
      );
  }

  private fecharFeature(): void {
    this.menuFeatureActionsService.deactivate({
      id: 'ccpf-transferirct' + this.credito.ct,
      title: 'CCPF - Transferir Crédito Tributário',
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO,
      class: CcpfTransfereCreditoComponent
    });
  }

  ngOnDestroy(): void {
    this.featureEventSub?.unsubscribe();
    this.updateSubscription?.unsubscribe();
  }
}
