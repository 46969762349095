import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedSuiteRfbModule } from '../../../shared/shared-suite-rfb.module';
import { CcpfSuiteRfbModule } from './ccpf-suite-rfb.module';
import { ConsultaCreditosComponent } from './components/consulta-creditos/consulta-creditos.component';
import { CcpfConsultaCreditoComponentesTableComponent } from './components/consulta-creditos/table-componentes/consulta-credito-componentes-table.component';
import { CcpfConsultaCreditoPagamentosTableComponent } from './components/consulta-creditos/table-pagamentos/consulta-credito-pagamentos-table.component';
import { CcpfContribuinteDepuradoModule } from './components/contribuinte-depurado/contribuinte-depurado.module';
import { CCPFDebitoContaComponent } from './components/debito-conta/debito-conta.component';
import { CCPFDemonstrativoCotasComponent } from './components/demonstrativo-cotas/demonstrativo-cotas.component';
import { EventosCreditoTributarioFiltros } from './components/eventos-credito-tributario-filtros/eventos-credito-tributario-filtros.component';
import { EventosCreditoTributario } from './components/eventos-credito-tributario/eventos-credito-tributario.component';
import { FiltrosPesquisaCcpfComponent } from './components/filtros-pesquisa-ccpf/filtros-pesquisa-ccpf.component';
import { CcpfAlocaPagamentoComponent } from './components/pagamento/aloca-pagamento/aloca-pagamento.component';
import { CcpfAlocaPagamentoDetalheAlocacoesComponent } from './components/pagamento/aloca-pagamento/detalhe/aloca-pagamento-detalhe-alocacoes.component';
import { CCPFAlocacaoDebitoComponent } from './components/pagamento/alocacao-debito/alocacao-debito.component';
import { CCPFAlocacaoDebitoTableComponentesComponent } from './components/pagamento/alocacao-debito/table-componentes/alocacao-debito-table-componentes.component';
import { CcpfComponentesDebitoComponent } from './components/pagamento/consulta-pagamento/componentes-debito/componentes-debito.component';
import { ConsultaPagamentosCcpfComponent } from './components/pagamento/consulta-pagamento/consulta-pagamentos.component';
import { CcpfDebitosPagamentoComponent } from './components/pagamento/consulta-pagamento/debitos-pagamento/debitos-pagamento.component';
import { FiltrosPesquisaCcpfPagamentoComponent } from './components/pagamento/filtros-pesquisa-pagamento/filtros-pesquisa-pagamento.component';
import { CCPFRevalidacaoDataCtComponent } from './components/revalidacao-data-ct/revalidacao-data-ct.component';
import { CcpfTransfereCreditoComponent } from './components/transfere-credito-tributario/transfere-credito-tributario.component';
import { MotivosTransferenciaPipe } from './pipes/motivos-transferencia.pipe';
import { NumeroComponentePipe } from './pipes/numero-componente.pipe';
import { CCPFService } from './services/ccpf.service';

@NgModule({
  declarations: [
    ConsultaCreditosComponent,
    FiltrosPesquisaCcpfComponent,
    CcpfConsultaCreditoPagamentosTableComponent,
    CcpfConsultaCreditoComponentesTableComponent,
    FiltrosPesquisaCcpfPagamentoComponent,
    ConsultaPagamentosCcpfComponent,
    CcpfDebitosPagamentoComponent,
    EventosCreditoTributarioFiltros,
    EventosCreditoTributario,
    CcpfAlocaPagamentoComponent,
    CcpfAlocaPagamentoDetalheAlocacoesComponent,
    CcpfComponentesDebitoComponent,
    CCPFDebitoContaComponent,
    CCPFDemonstrativoCotasComponent,
    CCPFRevalidacaoDataCtComponent,
    CcpfTransfereCreditoComponent,
    MotivosTransferenciaPipe,
    CCPFAlocacaoDebitoComponent,
    CCPFAlocacaoDebitoTableComponentesComponent,
    NumeroComponentePipe,
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    CcpfSuiteRfbModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedSuiteRfbModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    SharedModule,
    PipesModule,
    CcpfContribuinteDepuradoModule,
    NgxCurrencyDirective,
  ],
  exports: [
    ConsultaCreditosComponent,
    FiltrosPesquisaCcpfComponent,
    CcpfConsultaCreditoPagamentosTableComponent,
    CcpfConsultaCreditoComponentesTableComponent,
    FiltrosPesquisaCcpfPagamentoComponent,
    ConsultaPagamentosCcpfComponent,
    CcpfDebitosPagamentoComponent,
    EventosCreditoTributarioFiltros,
    EventosCreditoTributario,
    CcpfAlocaPagamentoComponent,
    CcpfComponentesDebitoComponent,
    CcpfTransfereCreditoComponent,
    MotivosTransferenciaPipe,
    CCPFAlocacaoDebitoComponent,
    NumeroComponentePipe,
  ],
  providers: [
    CCPFService,
    provideNgxMask()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CcpfModule { }
