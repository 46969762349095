import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CnpjsVinculadosDTO, QuantidadeCnpjsVinculadosDTO } from './model/cnpjs-vinculados';
import { GenericResponse } from 'src/app/model/generic-response';

@Injectable()
export class CnpjsVinculadosService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterCnpjsVinculados(cpf: string): Observable<GenericResponse> {
    const url = `${this.baseUrl}/cnpj/vinculados/${cpf}`;
    console.log("url obterCnpjsVinculados: " + url);
    return this.http.get<GenericResponse>(url);
  }

  public obterQuantidadeCnpjsVinculados(cpf: string): Observable<QuantidadeCnpjsVinculadosDTO[]> {
    const url = `${this.baseUrl}/cnpj/vinculados/${cpf}/quantidade`;
    console.log("url obterQuantidadeCnpjsVinculados: " + url);
    return this.http.get<QuantidadeCnpjsVinculadosDTO[]>(url);
  }

}
