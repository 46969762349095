import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AllowedFeaturePipe } from '@serpro/ngx-suite-rfb';
import { CookieService } from 'ngx-cookie-service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppSuiteRfbModule } from './app-suite-rfb.module';
import { AppComponent } from './app.component';
import { CsrfTokenInterceptor } from './interceptors/csrf-token-interceptor';
import { HttpRequestInterceptor } from './interceptors/http-request-interceptor'; ///
import { PipesModule } from './shared/pipes/pipes.module';
import { SharedModule } from './shared/shared.module';
import { WorkplaceModule } from './workplace/workplace.module';
import { environment } from 'src/environments/environment';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
registerLocaleData(localePt, 'pt');

const providers = [
  { provide: LOCALE_ID, useValue: 'pt' },
  CookieService,
  AllowedFeaturePipe,
  provideNgxMask(),
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
] as Provider[];

if( environment.csrfEnabled ) {
  console.log("CSRF HABILITADO");
  // Usamos um interceptor customizado pois o interceptor CSRF padrão do Angular,
  // HttpXsrfInterceptor, disponível no módulo HttpClientXsrfModule, somente aplica
  // a sua lógica em chamadas para URLs relativas, enquanto o frontend do VIA sempre
  // invoca os serviços do gateway usando URLs absolutas (http://...), além de também 
  // somente aplicar o token no cabeçalho de métodos HTML destinados a modificação de
  // recursos (POST, PUT, DELETE, PATCH), mas queremos aplicar em todos os métodos
  providers.push({ provide: HTTP_INTERCEPTORS, useClass: CsrfTokenInterceptor, multi: true });
}
else {
  console.log("CSRF DESABILITADO");  
};

@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppSuiteRfbModule,
    HttpClientModule,
    NgxExtendedPdfViewerModule,
    SharedModule,
    PipesModule.forRoot(),
    WorkplaceModule,
    ModalModule.forRoot(),
    NgxMaskDirective, NgxMaskPipe
  ],
  declarations: [
    AppComponent
  ],
  providers,
  bootstrap: [AppComponent]
})
export class AppModule { }
