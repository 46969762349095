import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ConsultaObrigatoriedadeDirfComponent } from '../consulta/consulta-obrigatoriedade-dirf.component';

@Component({
  templateUrl: './pesquisa-obrigatoriedade-dirf.component.html',
  styleUrls: ['./pesquisa-obrigatoriedade-dirf.component.css']
})
export class PesquisaObrigatoriedadeDirfComponent implements IDynamicPanel {

  titulo = 'Pessoa Jurídica - Consultar Obrigatoriedade DIRF - Filtros de Pesquisa';

  cnpjFormName = 'cnpj';
  anoCalendarioFormName = 'anoCalendario';

  formGroup: UntypedFormGroup;

  ni: string;

  scopeId: string;
  initError: Error;
  filtroColapsado = false;
  exibeResultado = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private messageService: ScopedMessageService) { }

  get cnpj(): string {
    const cnpjMasked = this.formGroup.get(this.cnpjFormName).value as string;
    return this.unmaskNi(cnpjMasked);
  }

  private set cnpj(value: string) {
    this.formGroup.get(this.cnpjFormName).setValue(value);
  }

  get anoCalendario(): string {
    return this.formGroup.get(this.anoCalendarioFormName).value as string;
  }

  init(ni: string): void {
    this.ni = ni;
    this.scopeId = this.messageService.scopeId;

    this.formGroup = this.formBuilder.group({
      cnpj: ['', Validators.required],
      anoCalendario: ['', Validators.required]
    });

    if (this.ni) {
      this.titulo = this.titulo = `Pessoa Jurídica - Obrigatoriedade DIRF - CNPJ: ${FormatCnpjPipe.formatCNPJ(this.ni)}`;
      this.cnpj = ni;
      this.formGroup.get(this.cnpjFormName).disable();
    }
  }

  onSubmit(): void {
    if (this.ni) {
      this.exibeResultado = true;
      this.filtroColapsado = true;
      return;
    }

    this.modalService.show(ConsultaObrigatoriedadeDirfComponent, {
      class: 'via-modal-md',
      initialState: {
        cnpj: this.cnpj,
        anoCalendario: this.anoCalendario
      }
    });
  }

  colapsar(collapsed: boolean): void {
    this.filtroColapsado = collapsed;
  }

  esconderResultado(): void {
    this.exibeResultado = false;
    this.filtroColapsado = false;
  }

  private unmaskNi(ni: string): string {
    return ni?.replace(/[^\d]+/g, '');
  }
}
