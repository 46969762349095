import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CCPFService } from '../../services/ccpf.service';

@Component({
  selector: 'via-ccpf-contribuinte-depurado',
  templateUrl: './contribuinte-depurado.component.html',
  styleUrls: ['./contribuinte-depurado.component.css'],
  providers: [ScopedMessageService]
})
export class CCPFContribuinteDepuradoComponent implements IDynamicPanel {

  titulo = 'Solicitação de Retorno do Contribuinte Depurado';

  cpfFormGroupName = 'cpf';

  formGroup: UntypedFormGroup;
  scopeId: string;
  initError: Error;

  processouResultado = false;
  stopLoading = false;
  solicitouRetorno = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ccpfService: CCPFService,
    private messageService: ScopedMessageService) { }

  init(ni: string): void {
    this.scopeId = this.messageService.scopeId;

    const _cpf = ni ? FormatCpfPipe.formatCPF(ni) : ni;

    this.formGroup = this.formBuilder.group({
      cpf: [_cpf, [Validators.required]]
    });

    if (_cpf) {
      this.formGroup.get(this.cpfFormGroupName).disable();
    }
  }

  onSubmit(): void {
    this.solicitouRetorno = true;
    this.stopLoading = false;
    this.processouResultado = false;

    this.ccpfService.solicitarRetornoContribuinteDepurado(this.cpf)
      .subscribe(
        _sucesso => {
          this.messageService.showInfo('Solicitação de retorno de depuração realizada com sucesso');
          this.processouResultado = true;
          this.stopLoading = true;
        },
        error => {
          this.messageService.showErrorException('Ocorreu um erro durante a solicitação de retorno de depuração', error);
          this.processouResultado = true;
          this.stopLoading = true;
        }
      );
  }

  private get cpf(): string {
    const maskedCpf = this.formGroup.get(this.cpfFormGroupName).value as string;
    return maskedCpf.replace(/[^\d]+/g, '');
  }
}
