import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedSuiteRfbModule } from "src/app/shared/shared-suite-rfb.module";
import { SharedModule } from "src/app/shared/shared.module";
import { HistoricoComponent } from "./historico.component";
import { HistoricoService } from "./historico.service";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';

@NgModule({
    declarations: [
        HistoricoComponent
    ],
    imports: [
        CommonModule,
        NgxMaskDirective, NgxMaskPipe,
        NgPipesModule,
        SharedModule,
        PipesModule,
        SharedSuiteRfbModule,
    ],
    providers: [
        HistoricoService,
        provideNgxMask()
    ],
    exports: [
        HistoricoComponent
    ]
})
export class HistoricoModule { }
