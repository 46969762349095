import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({ selector: '[suiteRfbCIBAlphaNum]' })
export class CIBAlphaNumDirective implements OnInit {

  constructor(
    private el: ElementRef,
  ) { }

  public ngOnInit(): void { }

  @HostListener('input', ['$event'])
  public onInputChange(event): void {
    const initialValue = this.el.nativeElement.value;
    var modifiedValue = initialValue.substr(0, 16).toUpperCase().replace(/[^0-9A-Z]*/g, ''); // permite alfanuméricos
    const len = modifiedValue.length;
    const mask = `$1${len > 1 ? '.' : ''}$2${len > 4 ? '.' : ''}$3${len > 7 ? '-' : ''}$4`;
    modifiedValue = modifiedValue.replace(
      /([0-9A-Z]{1})?([0-9A-Z]{3})?([0-9A-Z]{3})?([0-9A-Z]+)?/,
      mask
    );
    if (initialValue !== modifiedValue) {
      event.stopPropagation();
      this.setValue(modifiedValue);
    }
  }

  private setValue(value: string) {
    this.el.nativeElement.value = value;
    this.el.nativeElement.dispatchEvent(new CustomEvent('input'));
  }

}