import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DadosBasicosPJComponent } from '../cnpj/components/dados-basicos-pj/dados-basicos-pj.component';
import { DadosBasicosPFComponent } from '../cpf/components/dados-basicos-pf/dados-basicos-pf.component';
import { ActionPanelComponent } from './action-panel/action-panel.component';
import { AlertMessagesWithDatetimeComponent } from './alert-messages-with-datetime/alert-messages-with-datetime.component';
import { CodigoReceitaComponent } from './codigo-receita/codigo-receita.component';
import { CurrentFeatureComponent } from './current-feature/current-feature.component';
import { LocalMessagesComponent } from './directives/ni-validator/local-messages.component';
import { NiValidatorDirective } from './directives/ni-validator/ni-validator.directive';
import { DynamicModalRequestService } from './dynamic-modal/dynamic-modal-request.service';
import { DynamicModalComponent } from './dynamic-modal/dynamic-modal.component';
import { DynamicModalService } from './dynamic-modal/dynamic-modal.service';
import { DynamicActionPanelComponent } from './dynamic-panel/dynamic-action-panel/dynamic-action-panel.component';
import { LoadingAnimationComponent } from './loading-animation/loading-animation.component';
import { MenuFeatureActionComponent } from './menu-feature-action/menu-feature-action.component';
import { MenuFeatureActionsComponent } from './menu-feature-actions/menu-feature-actions.component';
import { ViaMenuModule } from './menu/menu.module';
import { ModalComponent } from './modal/modal.component';
import { NiComLinkComponent } from './ni-com-link/ni-com-link.component';
import { PipesModule } from './pipes/pipes.module';
import { AjustaTamanhoNIService } from './services/ajusta-tamanho-ni.service';
import { SharedSuiteRfbModule } from './shared-suite-rfb.module';
import { ViaPaginationComponent } from './pagination/pagination.component';
import { ViaTabsModule } from './tabs/tabs.module';
import { MenuFeatureActionsService } from './menu-feature-actions/menu-feature-actions.service';
import { CpfCnpjMaskDirective } from './directives/ni-validator/cpf-cnpj-mask.directive';
import { CIBAlphaNumDirective } from './directives/ni-validator/cib-alpha-num.directive';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    PipesModule.forRoot(),
    SharedSuiteRfbModule,
    TooltipModule,
    ViaTabsModule,
    ViaMenuModule
  ],
  declarations: [
    CurrentFeatureComponent,
    MenuFeatureActionsComponent,
    MenuFeatureActionComponent,
    DynamicModalComponent,
    ActionPanelComponent,
    DynamicActionPanelComponent,
    LoadingAnimationComponent,
    AlertMessagesWithDatetimeComponent,
    LocalMessagesComponent,
    NiValidatorDirective,
    CpfCnpjMaskDirective,
    CIBAlphaNumDirective,
    ModalComponent,
    NiComLinkComponent,
    CodigoReceitaComponent,
    ViaPaginationComponent,
  ],
  exports: [
    CurrentFeatureComponent,
    MenuFeatureActionsComponent,
    MenuFeatureActionComponent,
    ActionPanelComponent,
    DynamicActionPanelComponent,
    LoadingAnimationComponent,
    AlertMessagesWithDatetimeComponent,
    LocalMessagesComponent,
    NiValidatorDirective,
    CpfCnpjMaskDirective,
    CIBAlphaNumDirective,
    ModalComponent,
    NiComLinkComponent,
    CodigoReceitaComponent,
    ViaTabsModule,
    ViaMenuModule,
    ViaPaginationComponent,
  ],
  providers: [
    BsModalRef,
    DynamicModalService,
    DynamicModalRequestService,
    AjustaTamanhoNIService,
    MenuFeatureActionsService,
  ],
  entryComponents: [
    DadosBasicosPFComponent,
    DadosBasicosPJComponent
  ]
})
export class SharedModule { }
