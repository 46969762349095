<div class="contorno">
  <div class="row">
    <div class="col-md-4">
      <label class="label-formulario control-label">
        Área
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.areaImovel |formatCurrency | vazio }}
      </p>
    </div>
    <div class="col-md-4">
      <label class="label-formulario control-label">
        Município
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.municipioImovel | vazio }}
      </p>
    </div>
    <div class="col-md-4">
      <label class="label-formulario control-label">
        UF
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.ufImovel | vazio }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label class="label-formulario control-label">
        UA CIB
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.uaNirf | vazio }}
      </p>
    </div>
    <div class="col-md-4">
      <label class="label-formulario control-label">
        NI Proprietário
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.niProprietario | formatCpfCnpjPipe: this.tipoDeNi(item.tipoNiProprietario) | vazio }}
      </p>
    </div>
    <div class="col-md-4">
      <label class="label-formulario control-label">
        UA NI Proprietário
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.uaNiProprietario | vazio }}
      </p>
    </div>
  </div>
</div>

<ng-template #templateVazio>
  <p class="dado-formulario form-control-static">{{ '' | vazio }}</p>
</ng-template>
