import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CCPFSuiteRfbModule } from './ccpf-suite-rfb.module';
import { CCPFCobrancaComponent, CCPFComponent, CCPFExigibilidadeComponent } from './ccpf.component';
import { CCPFService } from './ccpf.service';
import { CCPFTableComponent } from './table/ccpf-table.component';

@NgModule({
    declarations: [
        CCPFComponent,
        CCPFCobrancaComponent,
        CCPFExigibilidadeComponent,
        CCPFTableComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        CCPFSuiteRfbModule,
        TooltipModule
    ],
    providers: [
        CCPFService
    ]
})
export class CCPFModule { }
