<div [ngClass]="{ 'modal-container': !atendimento }">
    <suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
      <fieldset #fs>
        <div>
      <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
      <div class="container-fluid">
      <loading-animation *ngIf="!creditosTributarios && !initError; else tabelaDebitos;"></loading-animation>
      <ng-template #tabelaDebitos>
        <ng-container *ngIf="!stopLoading">
          <div class="container-fluid" *ngFor="let exercicio of exercicios">
            <div class="exercicio">
              <div class="exercicio-toggle" style="float:left">
                <suite-rfb-toggle #rowToggleExercicio>
                </suite-rfb-toggle>
              </div>
              <div class="exercicio-titulo">
                {{ exercicio }}
              </div>
            </div>
            <ng-container *ngIf="rowToggleExercicio.show">
              <div class="exercicio-conteudo">
                <div class="table-responsive">
                  <table suiteRfbTable [class.tabela_largura_minima]="this.atendimento">
                    <thead>
                      <tr>
                        <th class="text-center min-width">Ações</th>
                        <th class="text-center min-width">
                          <suite-rfb-toggle
                            title="Expandir/Contrair Todos"
                            (expanded)="expandedAll($event)">
                          </suite-rfb-toggle>
                        </th>
                        <th class="text-center min-width">CT</th>
                        <th class="text-center min-width">Receita</th>
                        <th class="text-center min-width">Data de Vencimento</th>
                        <th class="text-center min-width">Valor Original</th>
                        <th class="text-center min-width">Saldo Receita</th>
                        <th class="text-center">Situação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let credito of creditosTributarios[exercicio]| currentPage: currentPages[exercicio];"
                        class="vertical-align-middle">
                        <tr class="vertical-align-middle">
                          <td class="text-center">
                            <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
                            <ng-template #menuAcoes>
                              <div class="btn-group-vertical" role="group">
                                <button [disabled]="false" type="button" class="btn btn-default" (click)="consultarPagamentosDARF(credito); botaoAcoes.hide()">
                                  Pagamentos
                                </button>
                                <button [disabled]="false" type="button" class="btn btn-default" (click)="retornarSituacaoCT(credito); botaoAcoes.hide()">
                                  Retornar Situação CT
                                </button>
                                <button [disabled]="false" type="button" class="btn btn-default" (click)="alterarDemonstrativoQuotas(credito); botaoAcoes.hide()">
                                  Demonstrativo de Cotas
                                </button>
                                <button [disabled]="false" type="button" class="btn btn-default" (click)="alterarVencimentoCT(credito); botaoAcoes.hide()">
                                  Vencimento
                                </button>
                                <button [disabled]="false" type="button" class="btn btn-default" (click)="consolidarCT(credito); botaoAcoes.hide()">
                                  Consolidar
                                </button>
                                <button [disabled]="false" type="button" class="btn btn-default" (click)="transferirCreditoTributario(credito); botaoAcoes.hide()">
                                  Transferir
                                </button>
                              </div>
                            </ng-template>
                          </td>
                          <td>
                            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                          </td>
                          <td class="text-center">{{ credito.numeroCt | vazio }}</td>
                          <td class="text-center">
                            <via-codigo-receita [codigo]="credito.receita"></via-codigo-receita>
                          </td>
                          <td class="text-center">{{ credito.dataVencimento | formatDataPipe }}</td>
                          <td class="text-right">{{ credito.valorOriginal | formatCurrency }}</td>
                          <td class="text-right">{{ credito.saldoReceita | formatCurrency }}</td>
                          <td class="text-left">{{ credito.situacao | vazio}}</td>
                        </tr>
                        <ng-container *ngIf="rowToggle.show">
                          <tr></tr>
                          <tr>
                            <td colspan="8">
                              <div class="contorno-ccitr">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      NI Declarante
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.niDeclarante | formatCpfCnpjPipe : tipoDeNi(credito.tipoNiDeclarante) }}
                                    </p>
                                  </div>
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Número da PERDCOMP
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.numeroPerdComp | vazio }}
                                    </p>
                                  </div>
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Processo Transferência
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.processoTransferencia | vazio }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Tipo Lançamento
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.tipoLancamento | vazio }}
                                    </p>
                                  </div>
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Indicador de TDA
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.indicadorTDA | vazio }}
                                    </p>
                                  </div>
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Compensação
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.compensacao | vazio }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Saldo Multa
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.saldoMulta | formatCurrency }}
                                    </p>
                                  </div>
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Saldo Juros
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.saldoJuros | formatCurrency }}
                                    </p>
                                  </div>
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Unidade Monetária
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.unidadeMonetaria | vazio }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Origem
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.origem | vazio }}
                                    </p>
                                  </div>
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Natureza
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.natureza | vazio }}
                                    </p>
                                  </div>
                                  <div class="col-md-4">
                                    <label class="label-formulario control-label">
                                      Ano Numeração
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.anoNumeracao | vazio }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-3">
                                    <label class="label-formulario control-label">
                                      Numero da Declaração
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.numeroDeclaracao | vazio }}
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <label class="label-formulario control-label">
                                      Data Recepção Declaração
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.dataRecepcaoDeclaracao | formatDataPipe }}
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <label class="label-formulario control-label">
                                      Data Ciência AR
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.dataCienciaAR | formatDataPipe }}
                                    </p>
                                  </div>
                                  <div class="col-md-3">
                                    <label class="label-formulario control-label">
                                      Data Periodo Apuração
                                    </label>
                                    <p class="form-control-static dado-formulario">
                                      {{ credito.dataPeriodoApuracao | formatDataPipe }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12">
                                    <via-ccitr-table-componentes-credito
                                      [atendimento]="atendimento"
                                      [cpf]="cpf"
                                      [cib]="parametros.nirf"
                                      [credito]="credito"
                                      [componentes]="credito.componentes"
                                      [parametrosPesquisa]="parametros">
                                    </via-ccitr-table-componentes-credito>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12">
                                    <via-ccitr-table-pagamentos
                                      [atendimento]="atendimento"
                                      [pagamentos]="credito.pagamentos"
                                      [cib]="parametros.nirf"
                                      [ni]="credito.niDeclarante"
                                      [numeroCT]="credito.numeroCt"
                                      [parametrosPesquisa]="parametros">
                                    </via-ccitr-table-pagamentos>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                    </tbody>
                    <tfoot>
                      <tr class="tabela-acoes text-left">
                        <td colspan="8">
                          <via-pagination [itemAmount]="creditosTributarios[exercicio].length" [(page)]="currentPages[exercicio]">
                          </via-pagination>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="tabela-acoes text-left total-consulta-ccitr" *ngIf="!initError">
            Total de Registros: {{ totalExercicios }}
          </div>
        </ng-container>
      </ng-template>
    </div>
    </div>
    </fieldset>
  </suite-rfb-collapse>
</div>


