<div [id]="onlyFor" class="alert" [ngClass]="{'alert-success': message.type === 'INFO', 'alert-warning': message.type === 'WARN', 'alert-danger': message.type === 'ERROR'}" role="alert" *ngFor="let message of messages">
  <div class="alert-panel">
    <div class="alert-icone">
      <span *ngIf="message.type === 'INFO'" class="material-icons icon-48">&#xE86C;</span>
      <span *ngIf="message.type === 'WARN'" class="material-icons icon-48">&#xE002;</span>
      <span *ngIf="message.type === 'ERROR'" class="material-icons icon-48">&#xE000;</span>
    </div>
    <div class="alert-mensagem">
      <div class="title" style="margin-top: 1%; margin-left: -4%;">{{message.title}}</div>
    </div>
    <div class="close" *ngIf="message.options && message.options.dismissible" style="margin-top: 1%">
      <span class="close-icon material-icons icon-24" (click)="dismiss(message)">&#xE5CD;</span>
    </div>
  </div>
</div>
