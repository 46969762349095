import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DetalheImovelRural } from '../models/detalhe-imovel-rural';
import { ResumoImovelRural } from '../models/resumo-imovel-rural';

@Injectable()
export class CafirService {

  private readonly baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterImoveisRurais(ni: string): Observable<ResumoImovelRural[]> {
    const url = `${this.baseUrl}/cafir/${ni}/imoveis`;
    return this.http.get<ResumoImovelRural[]>(url);
  }

  public obterDetalheImovelRural(ni: string, cib: string): Observable<DetalheImovelRural> {
    const url = `${this.baseUrl}/cafir/${ni}/imoveis/${cib}`;
    return this.http.get<DetalheImovelRural>(url);
  }
}
