import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FeatureAction } from 'src/app/shared/model/feature';
import { ConsultaPfComponent } from '../../consulta-pf/consulta-pf.component';

@Component({
  selector: 'via-pesquisa-cpf',
  templateUrl: './pesquisa-cpf.component.html',
  styleUrls: ['./pesquisa-cpf.component.css']
})
export class PesquisaCpfComponent implements OnInit {

  cpfFormGroupName: string = 'cpf';

  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dynamicModalService: DynamicModalRequestService) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      cpf: ['', [Validators.required]]
    });
  }

  private get cpf(): string {
    const maskedCpf = this.formGroup.get(this.cpfFormGroupName).value as string;
    return maskedCpf.replace(/[^\d]+/g, '');
  }

  get cpfCtrl(): UntypedFormControl {
    return this.formGroup.get(this.cpfFormGroupName) as UntypedFormControl;
  }

  onSubmit(): void {
    const consultarPfFeature = {
      id: `ConsultarPessoaFisica_${this.cpf}`,
      title: 'Pessoa Física - Consultar',
      class: ConsultaPfComponent,
      parametros: {
        cpf: this.cpf,
        isModal: true,
        imprimir: true,
      }
    } as FeatureAction;

    this.dynamicModalService.abrirModal(consultarPfFeature);
  }
}
