import { isWarning } from './../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilService } from 'src/app/shared/util/util.service';
import { VencimentoCTCcitr } from './../../../../models/vencimento-ct';
import { NumberUtilService } from './../../../../../shared/util/number-util.service';
import { CcitrBase } from './../../base/CcitrBase.component';
import { CCITRService } from './../../services/ccitr.service';
import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { CodigoDescricaoDTO } from 'src/app/cnpj/model/codigo-descricao-dto';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
@Component({
  templateUrl: './altera-vencimento-ct.component.html',
  styleUrls: ['./altera-vencimento-ct.component.css', '../../base/ccitr.css'],
  providers: [ScopedMessageService],
})

export default class AlteraVencimentoCTComponent extends CcitrBase implements IDynamicPanel  {

  titulo: string;
  sistemasInteresseDisponiveis: CodigoDescricaoDTO[] = [];

  dataVencimento: string;
  form: UntypedFormGroup;
  dataCienciaAIName: string = "dataCienciaAI";
  dataVencimentoName: string =  'dataVencimento';

  showCloseModal = true;
  scopeId: string;

  @Input() parametros: VencimentoCTCcitr;

  vencimentoCT: VencimentoCTCcitr;

  initError: Error;

  constructor(
    private fb: UntypedFormBuilder,
    private utilService: UtilService,
    private cCITRService: CCITRService,
    private numberUtilService: NumberUtilService,
    private messageService: ScopedMessageService) {
      super();
    }

  init(_ni: string, params?: OptionalParams): void {
    super.init(_ni, params);
    this.scopeId = this.messageService.scopeId;
    this.showCloseModal = false;
    this.parametros = params as VencimentoCTCcitr;

    this.dataVencimento = this.parametros.dataVencimento;

    const numeroCTFormatado: String = this.numberUtilService.leftZeroes(this.parametros.numeroCT, 9);

    this.titulo = "Vencimento do CT: " + numeroCTFormatado;

    //this.recuperarCTDepurados();
    this.vencimentoCT = this.parametros;

    this.form = this.fb.group({
      dataVencimento: [this.dataVencimento],
      dataCienciaAI: ['']
    });

}

private get dataCienciaAI(): Date {
  return this.form.get(this.dataCienciaAIName).value as Date;
}

get dataCienciaAICtrl(): UntypedFormControl {
  return this.form.get(this.dataCienciaAIName) as UntypedFormControl;
}

onSubmit(): void {

  this.parametros.dataVencimento = this.utilService.converterDataParaTextoInvertidaSemHora(this.dataCienciaAI);

  this.cCITRService.alterarVencimentoCT(this.parametros).subscribe(
    _sucesso => {
      this.messageService.showInfo('Data de Vencimento do Crédito Tributário alterado com sucesso.');
      this.dataVencimento = this.parametros.dataVencimento;
      this.form.reset();
    },
    (err: HttpErrorResponse) => {
    if (isWarning(err.error)) {
      this.messageService.showWarning(err.error.message);
      } else {
        this.messageService.showErrorException('Ocorreu um erro ao solicitar a Alteração da Data de Vencimento do Crédito Tributário', err);
      }
    }
  );
}

}
