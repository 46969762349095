import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbPopoverModule, SuiteRfbTableModule, SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { ContaCorrenteItrComponent, ContaCorrenteItrPFAtivoComponent, ContaCorrenteItrPFSuspensoComponent, ContaCorrenteItrPJAtivoComponent, ContaCorrenteItrPJSuspensoComponent } from './conta-corrente-itr.component';
import { ContaCorrenteItrService } from './conta-corrente-itr.service';
import { ContaCorrenteItrTableComponent } from './table/conta-corrente-itr-table.component';

@NgModule({
    declarations: [
        ContaCorrenteItrTableComponent,
        ContaCorrenteItrComponent,
        ContaCorrenteItrPJAtivoComponent,
        ContaCorrenteItrPJSuspensoComponent,
        ContaCorrenteItrPFAtivoComponent,
        ContaCorrenteItrPFSuspensoComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbPopoverModule,
        SuiteRfbToggleModule,
    ],
    providers: [
        ContaCorrenteItrService
    ]
})
export class ContaCorrenteItrModule { }
