import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DetalheImovelRural } from '../../models/detalhe-imovel-rural';
import { ResumoImovelRural } from '../../models/resumo-imovel-rural';
import { CafirService } from '../../services/cafir.service';

/*
  ESTRUTURA PARA CONTROLAR A CHAMADA AO SERVIÇO DE DETALHE DO IMÓVEL,
  PARA QUE OCORRA APENAS NO PRIMEIRO EXPANDED DO TOGGLE
*/
type ControleImovel = {
  executouChamada: boolean;
  imovel: DetalheImovelRural;
};

@Component({
  selector: 'via-cafir-table',
  templateUrl: './cafir-table.component.html',
  styleUrls: ['./cafir-table.component.css']
})
export class CafirTableComponent implements OnInit {

  @Input() ni: string;
  @Input() imoveisRurais: ResumoImovelRural[];
  @Input() parentScopeId: string;

  detalhePorCib = new Map<string, ControleImovel>();

  scopeId: string;
  initError: Error;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private messageService: ScopedMessageService,
    private cafirService: CafirService) { }

  ngOnInit(): void {
    this.scopeId = this.parentScopeId ? this.parentScopeId : this.messageService.scopeId;

    this.imoveisRurais.forEach(imovel => {
      this.detalhePorCib.set(imovel.nirf, { executouChamada: false, imovel: null });
    });
  }

  onCarregarDetalhe(expanded: boolean, imovel: ResumoImovelRural): void {

    const executouChamada = this.detalhePorCib.get(imovel.nirf).executouChamada;

    if (expanded && !executouChamada) {
      this.obterDetalheImovelRural(imovel.nirf);
    }
  }

  private obterDetalheImovelRural(cib: string): void {
    this.cafirService.obterDetalheImovelRural(this.ni, cib).subscribe(
      response => {
        this.detalhePorCib.get(cib).executouChamada = true;
        this.detalhePorCib.get(cib).imovel = response;
      },
      error => {
        this.messageService.showErrorException(`Ocorreu um erro na consulta de detalhe do imóvel rural com CIB: ${cib}`, error);
        this.initError = error;
      }
    );
  }
}
