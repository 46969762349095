import { Component } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CnpjsVinculadosService } from './cnpjs-vinculados.service';
import { CnpjsVinculadosDTO } from './model/cnpjs-vinculados';
import { GenericResponse } from 'src/app/model/generic-response';

@Component({
  templateUrl: './cnpjs-vinculados.component.html',
  styleUrls: ['./cnpjs-vinculados.component.css']
})
export class CnpjsVinculadosComponent implements IDynamicPanel {
  initError: Error;

  titulo: string;
  loadingError$ = new Subject<boolean>();
  cnpjsVinculados$: Observable<CnpjsVinculadosDTO[]>;

  constructor(
    private cnpjsVinculadosService: CnpjsVinculadosService,
    private messageService: ScopedMessageService) { }

  init(cpf: string): void {
    this.titulo = `CNPJ's Vinculados - CPF ${FormatCpfPipe.formatCPF(cpf)}`;
    this.cnpjsVinculados$ = this.cnpjsVinculadosService.obterCnpjsVinculados(cpf)
    .pipe(
      map((response: GenericResponse) => response.dado as CnpjsVinculadosDTO[]),
      catchError(err => {
        this.loadingError$.next(true);
        this.messageService.showErrorException(this.titulo, err);
        return of<CnpjsVinculadosDTO[]>();
      })
    );
  }
}
