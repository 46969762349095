import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbPopoverModule, SuiteRfbTableModule, SuiteRfbToggleModule } from "@serpro/ngx-suite-rfb";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedModule } from "src/app/shared/shared.module";
import { SitfisPipesModule } from "../../pipes/sitfis-pipes.module";
import { ArrolamentoBensSiefTableComponent } from "./arrolamento-bens-sief-table/arrolamento-bens-sief-table.component";
import { ArrolamentoBensSiefComponent, ArrolamentoBensSiefPFComponent, ArrolamentoBensSiefPJComponent } from "./arrolamento-bens-sief.component";
import { ArrolamentoBensSiefService } from "./arrolamento-bens-sief.service";

@NgModule( {
    declarations: [
        ArrolamentoBensSiefComponent,
        ArrolamentoBensSiefPFComponent,
        ArrolamentoBensSiefPJComponent,
        ArrolamentoBensSiefTableComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbPopoverModule,
        SuiteRfbToggleModule,
        TooltipModule,
        ModalModule,
    ],
    providers: [
        ArrolamentoBensSiefService,
    ]
})
export class ArrolamentoBensSiefModule {}
