import { isWarning } from './../../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { ConsultaPagamentosComponent } from 'src/app/pagamento/components/consulta-pagamentos/consulta-pagamentos.component';
import { ParametrosPesquisaPagamento } from 'src/app/pagamento/models/parametros-pesquisa';
import { CcitrBase } from './../../../base/CcitrBase.component';
import { CNPJService } from 'src/app/shared/services/cnpj.service';
import { ParametrosPesquisaCcitr } from './../../../../../models/parametros-pesquisa-ccitr';
import { MenuFeatureActionsService } from './../../../../../../shared/menu-feature-actions/menu-feature-actions.service';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { Component, Input, QueryList, ViewChildren, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { LoadingService, Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { PagamentoCcitr, CreditoTributarioCcitr } from 'src/app/cobranca/models/credito-tributario-ccitr';
import { CcitrImpedirAlocacaoAutomaticaPagamentoComponent } from '../../impedir-alocacao-automatica-pagamento/ccitr-impedir-alocacao-automatica-pagamento.component';
import { CCITRService } from '../../../services/ccitr.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { LogService } from 'src/app/shared/services/log.service';
import { CONSTANTS } from '../../../constants';
import AlteraVencimentoCTComponent from '../../altera-vencimento-ct/altera-vencimento-ct.component';

@Component({
  selector: 'via-ccitr-table-pagamentos',
  templateUrl: 'consulta-credito-pagamentos-table.component.html',
  styleUrls: ['consulta-credito-pagamentos-table.component.css','../../../base/ccitr.css']
})
export class CcitrConsultaCreditoPagamentosTableComponent extends CcitrBase implements OnInit {

  @Input() pagamentos: PagamentoCcitr[];
  @Input() credito: string;
  @Input() cib: string;
  @Input() numeroCT: string;
  @Input() ni: string;
  @Input() atendimento: boolean = false;
  @Input() parametrosPesquisa: ParametrosPesquisaCcitr;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  semPagamento: Boolean = false;

 // pageSize: number = 20;
 // pageSizes: number[] = [10, 20];
  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private dynamicModalService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private cCITRService: CCITRService,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private loadingService: LoadingService
) {
  super();
  }

  private naoPossuiNumeroPagamento(pagamento: PagamentoCcitr): boolean {
    return !pagamento.numeroPagamento;
  }

  ngOnInit(): void {
    this.semPagamento = isEmpty(this.pagamentos);
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  desalocarPagamento(pagamento: PagamentoCcitr) {
    this.messageService.dismissMessages();
    this.cCITRService.desalocarPagamento(this.ni,
                                        this.cib,
                                        this.numeroCT,
                                        pagamento.componente,
                                        pagamento.numeroPagamento
                                        ).subscribe(
      _sucesso => {
        this.messageService.showInfo('Pagamento desalocado com sucesso.');
        this.cCITRService.atualizarListagemCreditos(this.parametrosPesquisa);
      }, (err: HttpErrorResponse) => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
        } else {
          this.messageService.showErrorException('Ocorreu um erro ao desalocar pagamento.', err);
        }
      }
    ).add(() => {
      this.topoDaPaginaMensagem()
    });

  }

  impedirAlocacaoAutomatica(item: PagamentoCcitr) {

    const parametros = {
      cib: this.cib,
      numeroPagamento: item.numeroPagamento,
      atendimento: this.atendimento
    };

    const impedirAlocacaoAutomaticaFeature = {
      id: `ConsultaPagamentos_ImpedirAlocacaoAutomatica_${item.numeroPagamento}`,
      title: 'Pagamentos - Impedir Alocação Automática',
      class: CcitrImpedirAlocacaoAutomaticaPagamentoComponent,
      parametros: parametros
    } as FeatureAction;

    if (this.atendimento) {
      impedirAlocacaoAutomaticaFeature.active = true;
      impedirAlocacaoAutomaticaFeature.rolesAllowed = [];
      impedirAlocacaoAutomaticaFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(impedirAlocacaoAutomaticaFeature);
      return;
    }

    this.dynamicModalService.abrirModal(impedirAlocacaoAutomaticaFeature);
  }

  consultarPagamento(item: PagamentoCcitr): void {
    const parametrosPesquisa = {
      numeroPagamento: item.numeroPagamento
    } as ParametrosPesquisaPagamento;

    const consultaPagamentosFeature = {
      id: `ConsultaPagamentos_Pagamento_${item.numeroPagamento}`,
      title: 'Pagamentos - Consultar',
      class: ConsultaPagamentosComponent,
      parametros: {
                    parametrosPesquisa: parametrosPesquisa,
                    atendimento: this.atendimento,
                    cpf: this.parametrosPesquisa.cpfContribuinte || '',
                    cnpj: this.parametrosPesquisa.cnpjContribuinte || '',
                  }
    } as FeatureAction;

    if (this.atendimento) {
      consultaPagamentosFeature.active = true;
      consultaPagamentosFeature.rolesAllowed = [];
      consultaPagamentosFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(consultaPagamentosFeature);
      return;
    }

    this.dynamicModalService.abrirModal(consultaPagamentosFeature);
  }

  liberarAlocacaoAutomatica(item: PagamentoCcitr) {
    this.loadingService.show();
    this.cCITRService.liberarAlocacaoAutomaticaPagamento(this.cib, item.numeroPagamento).subscribe(
      _sucess => {
        this.messageService.showInfo('Operação Liberar Alocação Automática realizada com sucesso', `Pagamento ${item.numeroPagamento}`);
      },
      (err: HttpErrorResponse) => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
          return null;
        } else {
          this.logService.logError('CcitrConsultaCreditoPagamentosTableComponent', 'CcitrConsultaCreditoPagamentosTableComponent', err);
          this.messageService.showErrorException('Ocorreu um erro ao tentar executar a operação Liberar Alocação Automática do Pagamento', err);
        }
      }
    ).add(() => {
      this.loadingService.hide();
    });
  }

}
