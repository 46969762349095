import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { SuiteRfbPaginationModule, SuiteRfbCollapseModule, SuiteRfbTableModule } from '@serpro/ngx-suite-rfb';
import { ParcelamentoRefisAtivoComponent, ParcelamentoRefisSuspensoComponent } from './parcelamento-refis.component';
import { ParcelamentoRefisComponent } from './parcelamento-refis.component';
import { ParcelamentoRefisService } from './parcelamento-refis.service';

@NgModule( {
    declarations: [
        ParcelamentoRefisComponent,
        ParcelamentoRefisAtivoComponent,
        ParcelamentoRefisSuspensoComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule
    ],
    providers: [
        ParcelamentoRefisService
    ]
})
export class ParcelamentoRefisModule {}
