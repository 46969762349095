import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SuiteRfbCollapseModule, SuiteRfbToggleModule } from "@serpro/ngx-suite-rfb";
import { SuiteRfbPaginationModule } from "@serpro/ngx-suite-rfb";
import { SuiteRfbPopoverModule } from "@serpro/ngx-suite-rfb";
import { SuiteRfbTableModule } from "@serpro/ngx-suite-rfb";
import { SuiteRfbOutputMaskModule } from "@serpro/ngx-suite-rfb";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedModule } from "src/app/shared/shared.module";
import { SitfisPipesModule } from "../../pipes/sitfis-pipes.module";
import { DeclaracaoAusenteService } from "./declaracao-ausente.service";

import { DeclaracaoAusenteComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusenteDctfComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusenteDirfComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusenteDirpfComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusenteSimeiComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusenteObrapComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusentePJDitrComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusentePFDitrComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusentePJGfipComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusentePFGfipComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusentePgdasComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusenteEfdComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusenteEcfComponent } from "./declaracao-ausente.component";
import { DeclaracaoAusenteDefisComponent } from "./declaracao-ausente.component";

import { DeclaracaoAusenteTableComponent } from "./table/declaracao-ausente-table.component";
import { DeclaracaoAusenteDctfTableComponent } from "./table/declaracao-ausente-dctf-table.component";
import { DeclaracaoAusenteDirfTableComponent } from "./table/declaracao-ausente-dirf-table.component";
import { DeclaracaoAusenteDirpfTableComponent } from "./table/declaracao-ausente-dirpf-table.component";
import { DeclaracaoAusenteSimeiTableComponent } from "./table/declaracao-ausente-simei-table.component";
import { DeclaracaoAusenteObrapTableComponent } from "./table/declaracao-ausente-obrap-table.component";
import { DeclaracaoAusenteDitrTableComponent } from "./table/declaracao-ausente-ditr-table.component";
import { DeclaracaoAusenteGfipTableComponent } from "./table/declaracao-ausente-gfip-table.component";
import { DeclaracaoAusentePgdasTableComponent } from "./table/declaracao-ausente-pgdas-table.component";
import { DeclaracaoAusenteEfdTableComponent } from "./table/declaracao-ausente-efd-table.component";
import { DeclaracaoAusenteEcfTableComponent } from "./table/declaracao-ausente-ecf-table.component";
import { DeclaracaoAusenteDefisTableComponent } from "./table/declaracao-ausente-defis-table.component";
import { ObrigatoriedadeDirfModule } from "src/app/obrigatoriedade-dirf/obrigatoriedade-dirf.module";
import { OmissosModule } from "src/app/cpf/components/omissos/omissos.module";
import { CafirModule } from "src/app/cafir/cafir.module";

@NgModule({
    declarations: [
        DeclaracaoAusenteComponent,
        DeclaracaoAusenteDctfComponent,
        DeclaracaoAusenteDirfComponent,
        DeclaracaoAusenteDirpfComponent,
        DeclaracaoAusenteSimeiComponent,
        DeclaracaoAusenteObrapComponent,
        DeclaracaoAusentePJDitrComponent,
        DeclaracaoAusentePFDitrComponent,
        DeclaracaoAusentePJGfipComponent,
        DeclaracaoAusentePFGfipComponent,
        DeclaracaoAusentePgdasComponent,
        DeclaracaoAusenteEfdComponent,
        DeclaracaoAusenteEcfComponent,
        DeclaracaoAusenteDefisComponent,
        DeclaracaoAusenteTableComponent,
        DeclaracaoAusenteDctfTableComponent,
        DeclaracaoAusenteDirfTableComponent,
        DeclaracaoAusenteDirpfTableComponent,
        DeclaracaoAusenteSimeiTableComponent,
        DeclaracaoAusenteObrapTableComponent,
        DeclaracaoAusenteDitrTableComponent,
        DeclaracaoAusenteGfipTableComponent,
        DeclaracaoAusentePgdasTableComponent,
        DeclaracaoAusenteEfdTableComponent,
        DeclaracaoAusenteEcfTableComponent,
        DeclaracaoAusenteDefisTableComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbPopoverModule,
        SuiteRfbOutputMaskModule,
        SuiteRfbToggleModule,
        TooltipModule,
        ModalModule,
        ObrigatoriedadeDirfModule,
        OmissosModule,
        CafirModule,
    ],
    providers: [
        DeclaracaoAusenteService
    ]
})
export class DeclaracaoAusenteModule { }
