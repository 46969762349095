import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { SuiteRfbPaginationModule, SuiteRfbCollapseModule, SuiteRfbTableModule } from '@serpro/ngx-suite-rfb';
import { IrregularidadeCadastralPFComponent, IrregularidadeCadastralPJComponent } from './irregularidade-cadastral.component';
import { IrregularidadeCadastralComponent } from './irregularidade-cadastral.component';
import { IrregularidadeCadastralService } from './irregularidade-cadastral.service';

@NgModule( {
    declarations: [
        IrregularidadeCadastralComponent,
        IrregularidadeCadastralPFComponent,
        IrregularidadeCadastralPJComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule
    ],
    providers: [
        IrregularidadeCadastralService
    ]
})
export class IrregularidadeCadastralModule {}
