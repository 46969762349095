import { Component, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {  NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FeatureAction } from 'src/app/shared/model/feature';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { NaturezaDocumentoArrecadacao } from '../../models/natureza-documento-arrecadacao';
import { FaixaArrecadacao, NISemDV, NivelPesquisa, ParametrosPesquisaPagamento, PeriodoArrecadacao, TipoNivelPesquisa } from '../../models/parametros-pesquisa';
import { TipoNIRDOC } from '../../models/tipo-ni-rdoc';
import { PagamentoService } from '../../services/pagamento.service';
import { ConsultaPagamentosComponent } from '../consulta-pagamentos/consulta-pagamentos.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './filtros-pesquisa-pagamento.component.html',
  styleUrls: ['./filtros-pesquisa-pagamento.component.css'],
})
export class FiltrosPesquisaPagamentoComponent implements IDynamicPanel {

  @ViewChild('templateModalErro') public templateModalErro: TemplateRef<any>;

  titulo = 'Pagamento - Consultar - Filtros de Pesquisa';

  naturezasDocumentoArrecadacao: NaturezaDocumentoArrecadacao[] = [];

  numeroPagamentoName = 'numeroPagamento';
  numeroDocumentoName = 'numeroDocumento';
  numeroReferenciaName = 'numeroReferencia';
  dataArrecadacaoInicioName = 'dataArrecadacaoInicio';
  dataArrecadacaoFimName = 'dataArrecadacaoFim';
  cpfSemDvName = 'cpfSemDv';
  cnpjSemDvName = 'cnpjSemDv';
  niInvalidoName = 'niInvalido';
  bancoName = 'banco';
  agenciaName = 'agencia';
  faixaInicioName = 'faixaInicio';
  faixaFimName = 'faixaFim';
  naturezaDocumentoName = 'naturezaDocumento';
  receitaName = 'receita';
  tipoPesquisaName = 'tipoPesquisa';
  pesquisaDelegaciaCodigoName = 'pesquisaDelegaciaCodigo';
  pesquisaRegiaoCodigoName = 'pesquisaRegiaoCodigo';

  readonly tipoPesquisaDelegacia = TipoNivelPesquisa.DELEGACIA;
  readonly tipoPesquisaRegiao = TipoNivelPesquisa.REGIAO;
  readonly tipoPesquisaBrasil = TipoNivelPesquisa.BRASIL;

  formGroup: UntypedFormGroup;
  initError: Error;
  ni: string;
  filtroColapsado = false;
  exibeResultado = false;
  parametros: ParametrosPesquisaPagamento;

  currencyMaskOptions = {
    thousands: '.',
    decimal: ',',
    prefix: '',
    allowNegative: false,
    nullable: true,
    min: null,
    max: null
  };

  private modalRef: BsModalRef;

  private dataHoje = new Date();
  private dataDozeExerciciosAtras = new Date(this.dataHoje.getFullYear() - 12, this.dataHoje.getMonth(), this.dataHoje.getDate());

  constructor(
    private formBuilder: UntypedFormBuilder,
    private pagamentoService: PagamentoService,
    private dynamicModalRequest: DynamicModalRequestService,
    private maskPipe: NgxMaskPipe,
    private bsModalService: BsModalService) { }

  init(ni: string = ''): void {
    this.ni = ni;

    if (this.ni) {
      this.titulo = `${this.titulo} - ${this.ni.length === 11 ?
        FormatCpfPipe.formatCPF(this.ni) :
        FormatCnpjPipe.formatCNPJ(this.ni)}`;
    }

    this.formGroup = this.formBuilder.group({
      numeroPagamento: [''],
      numeroDocumento: [''],
      numeroReferencia: [''],
      dataArrecadacaoInicio: [this.dataDozeExerciciosAtras],
      dataArrecadacaoFim: [this.dataHoje],
      cpfSemDv: [''],
      cnpjSemDv: [''],
      niInvalido: [''],
      banco: [''],
      agencia: [''],
      faixaInicio: [null],
      faixaFim: [null],
      naturezaDocumento: [''],
      receita: [''],
      tipoPesquisa: [''],
      pesquisaDelegaciaCodigo: [''],
      pesquisaRegiaoCodigo: ['']
    });

    this.onFormChanges();

    this.preencherCpfOuCnpj();
    this.bloquearNumeroPagamento();
    this.bloquearNumeroDocumento();
    this.obterNaturezasDocumentoArrecadacao();
    this.bloquearInputsNivelPesquisa();
  }

  onSubmit(): void {
    this.parametros = {
      numeroPagamento: this.numeroPagamento,
      numeroDocumento: this.numeroDocumento,
      numeroReferencia: this.numeroReferencia,
      periodoArrecadacao: this.periodoArrecadacao,
      niSemDv: this.niSemDv,
      codigoBanco: this.codigoBanco,
      codigoAgencia: this.codigoAgencia,
      faixaArrecadacao: this.faixaArrecadacao,
      naturezaDocumento: this.naturezaDocumento,
      codigoReceita: this.codigoReceita,
      nivelPesquisa: this.nivelPesquisa,
    } as ParametrosPesquisaPagamento;

    if (this.camposNivelPesquisaEhInvalido(this.parametros.nivelPesquisa)) {
      this.abrirModalErroNivelPesquisa()
      return;
    }
    
    if (this.ni) {
      this.exibeResultado = true;
      this.filtroColapsado = true;
    } else {
      this.exibeResultado = false;
      let feature = new FeatureAction();
      feature.class = ConsultaPagamentosComponent;
      feature.parametros = {
                             parametrosPesquisa: this.parametros,
                             atendimento: false
                            };
      this.dynamicModalRequest.abrirModal(feature);
    }
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

  private get numeroPagamento(): string {
    return this.formGroup.get(this.numeroPagamentoName).value as string;
  }

  private get numeroDocumento(): string {
    return this.formGroup.get(this.numeroDocumentoName).value as string;
  }

  private get numeroReferencia(): string {
    return this.formGroup.get(this.numeroReferenciaName).value as string;
  }

  private get periodoArrecadacao(): PeriodoArrecadacao {
    const dataInicial = this.formGroup.get(this.dataArrecadacaoInicioName).value as Date;
    const dataFinal = this.formGroup.get(this.dataArrecadacaoFimName).value as Date;
    return { dataInicial, dataFinal } as PeriodoArrecadacao;
  }

  private get niSemDv(): NISemDV {
    const niInvalido = Boolean(this.formGroup.get(this.niInvalidoName).value);

    const cpf = this.formGroup.get(this.cpfSemDvName).value as string;
    if (cpf) {
      const cpfUnmasked = this.unmaskNi(cpf);
      const tipoNi = niInvalido ? TipoNIRDOC.NI_INVALIDO : TipoNIRDOC.CPF;
      return { ni: cpfUnmasked, tipoNi: tipoNi, niInvalido } as NISemDV;
    }

    const cnpj = this.formGroup.get(this.cnpjSemDvName).value as string;
    if (cnpj) {
      const cnpjUnmasked = this.unmaskNi(cnpj);
      const tipoNi = niInvalido ? TipoNIRDOC.NI_INVALIDO : TipoNIRDOC.CNPJ;
      return { ni: cnpjUnmasked, tipoNi: tipoNi, niInvalido } as NISemDV;
    }
  }

  private get codigoBanco(): string {
    return this.formGroup.get(this.bancoName).value as string;
  }

  private get codigoAgencia(): string {
    return this.formGroup.get(this.agenciaName).value as string;
  }

  private get faixaArrecadacao(): FaixaArrecadacao {
    const valorInicial = this.formGroup.get(this.faixaInicioName).value as number;
    const valorFinal = this.formGroup.get(this.faixaFimName).value as number;
    return { valorInicial, valorFinal } as FaixaArrecadacao;
  }

  private get naturezaDocumento(): NaturezaDocumentoArrecadacao {
    const naturezaDocumento = this.formGroup.get(this.naturezaDocumentoName).value;

    // QUANDO NENHUMA OPÇÃO ESTÁ SELECIONADA, O TIPO DESSE CAMPO VEM COMO string
    if (typeof (naturezaDocumento) === "object") {
      return naturezaDocumento as NaturezaDocumentoArrecadacao;
    }
  }

  private get codigoReceita(): string {
    return this.formGroup.get(this.receitaName).value as string;
  }

  private get nivelPesquisa(): NivelPesquisa {
    const tipoPesquisa = this.formGroup.get(this.tipoPesquisaName).value as TipoNivelPesquisa;

    switch (tipoPesquisa) {
      case this.tipoPesquisaDelegacia:
        const codigoDelegacia = this.formGroup.get(this.pesquisaDelegaciaCodigoName).value as string;
        return { tipo: tipoPesquisa, valor: codigoDelegacia } as NivelPesquisa;

      case this.tipoPesquisaRegiao:
        const codigoRegiao = this.formGroup.get(this.pesquisaRegiaoCodigoName).value as string;
        return { tipo: tipoPesquisa, valor: codigoRegiao } as NivelPesquisa;

      case this.tipoPesquisaBrasil:
        return { tipo: tipoPesquisa } as NivelPesquisa;
    }
  }

  private obterNaturezasDocumentoArrecadacao(): void {
    this.pagamentoService.obterNaturezasDocumentoArrecadacao().subscribe(
      response => this.naturezasDocumentoArrecadacao = response);
  }

  private unmaskNi(ni: string): string {
    return ni.replace(/[^\d]+/g, '');
  }

  private preencherCpfOuCnpj(): void {
    if (this.ni) {
      if (this.ni.length === 11) {
        const cpfSemDV = this.ni.slice(0, 9);
        const cpfFormatado = this.maskPipe.transform(cpfSemDV, '999.999.999');
        this.formGroup.get(this.cpfSemDvName).setValue(cpfFormatado);
      } else {
        const cnpjSemDV = this.ni.slice(0, 12);
        const cnpjFormatado = this.maskPipe.transform(cnpjSemDV, '99.999.999/9999');
        this.formGroup.get(this.cnpjSemDvName).setValue(cnpjFormatado);
      }

      this.formGroup.get(this.cpfSemDvName).disable();
      this.formGroup.get(this.cnpjSemDvName).disable();
      this.formGroup.get(this.niInvalidoName).disable();
    }
  }

  /**
   * BLOQUEIA O CAMPO 'Número do Pagamento' SE O QUADRO FOR ABERTO POR DENTRO DA ABA
   */
  private bloquearNumeroPagamento(): void {
    if (this.ni) {
      this.formGroup.get(this.numeroPagamentoName).disable();
    }
  }

  /**
   * BLOQUEIA O CAMPO 'Número do Documento' SE O QUADRO FOR ABERTO POR DENTRO DA ABA
   */
  private bloquearNumeroDocumento(): void {
    if (this.ni) {
      this.formGroup.get(this.numeroDocumentoName).disable();
    }
  }

  private bloquearInputsNivelPesquisa(): void {
    this.formGroup.get(this.pesquisaDelegaciaCodigoName).disable();
    this.formGroup.get(this.pesquisaRegiaoCodigoName).disable();
  }

  private onFormChanges(): void {
    this.formGroup.get(this.numeroPagamentoName).valueChanges.subscribe(
      numeroPagamento => {
        if (numeroPagamento?.length > 0) {
          this.limparPeriodoArrecadacao();
        }
      });

    this.formGroup.get(this.numeroDocumentoName).valueChanges.subscribe(
      numeroDocumento => {
        if (numeroDocumento?.length > 0) {
          this.limparPeriodoArrecadacao();
        }
      });

    this.formGroup.get(this.tipoPesquisaName).valueChanges.subscribe(
      tipoPesquisa => this.limparInputNivelPesquisa(tipoPesquisa)
    );
  }

  private limparPeriodoArrecadacao(): void {
    this.formGroup.get(this.dataArrecadacaoInicioName).setValue('');

    this.formGroup.get(this.dataArrecadacaoFimName).setValue('');
  }

  private limparInputNivelPesquisa(nivelPesquisa: TipoNivelPesquisa): void {
    const caixaEntradaCodigoDelegacia = this.formGroup.get(this.pesquisaDelegaciaCodigoName);
    const caixaEntradaCodigoRegiao = this.formGroup.get(this.pesquisaRegiaoCodigoName);

    this.bloquearInputsNivelPesquisa();
    caixaEntradaCodigoDelegacia.setValue('');
    caixaEntradaCodigoRegiao.setValue('');

    if (nivelPesquisa === this.tipoPesquisaDelegacia) {
      caixaEntradaCodigoDelegacia.enable();
    } else if (nivelPesquisa === this.tipoPesquisaRegiao) {
      caixaEntradaCodigoRegiao.enable();
    }
  }

  limparFormulario(): void {
    this.formGroup.reset();
    this.preencherCpfOuCnpj();
    this.bloquearInputsNivelPesquisa();
  }

  public fecharModal(): void {
    this.modalRef.hide();
  }
  
  private camposNivelPesquisaEhInvalido(nivelPesquisa: NivelPesquisa): boolean {
    return (nivelPesquisa?.tipo === this.tipoPesquisaDelegacia || nivelPesquisa?.tipo === this.tipoPesquisaRegiao) && !nivelPesquisa.valor;
  }

  private abrirModalErroNivelPesquisa(): void {
    this.modalRef = this.bsModalService.show(this.templateModalErro);
  }
  
}
