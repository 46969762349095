<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!debitosAgrupados && !initError; else tabelaDebitos;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaDebitos>
  <div class="container-fluid" *ngFor="let debitosCib of debitosAgrupados | keyvalue">
    <div class="cib">
      <div class="cib-toggle" style="float:left">
        <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
      </div>
      <div class="cib-titulo">
        CIB: {{ debitosCib.key }}
      </div>
    </div>
    <ng-container *ngIf="rowToggle.show">
      <div class="cib-conteudo">
        <via-conta-corrente-itr-table *ngFor="let debitosCibReceita of debitosCib.value | keyvalue"
          [nomeTributo]="debitosCibReceita.value[0].nomeTributo"
          [debitos]="debitosCibReceita.value"
          [isPessoaJuridica]="isPessoaJuridica"
          [isPessoaFisica]="isPessoaFisica"
          [isAtivos]="isAtivos"
          [isSuspensos]="isSuspensos"
          (emitirDarf)="emitirDarf($event)">
        </via-conta-corrente-itr-table>
      </div>
    </ng-container>
  </div>
</ng-template>
