import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbPopoverModule, SuiteRfbTableModule, SuiteRfbToggleModule } from "@serpro/ngx-suite-rfb";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedModule } from "src/app/shared/shared.module";
import { SitfisPipesModule } from "../../pipes/sitfis-pipes.module";
import { DebitoSiefComponent, DebitoSiefPFAtivoComponent, DebitoSiefPFSuspensoComponent, DebitoSiefPJAtivoComponent, DebitoSiefPJSuspensoComponent } from "./debito-sief.component";
import { DebitoSiefService } from "./debito-sief.service";
import { DebitoSiefTableComponent } from "./table/debito-sief-table.component";

@NgModule({
    declarations: [
        DebitoSiefTableComponent,
        DebitoSiefPJSuspensoComponent,
        DebitoSiefPJAtivoComponent,
        DebitoSiefPFAtivoComponent,
        DebitoSiefPFSuspensoComponent,
        DebitoSiefComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbPopoverModule,
        SuiteRfbToggleModule,
        TooltipModule,
        ModalModule,
    ],
    providers: [
        DebitoSiefService
    ]
})
export class DebitoSiefModule { }
