import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FeatureAction } from 'src/app/shared/model/feature';
import { ConsultaPjComponent } from '../../consulta-pj/consulta-pj.component';

@Component({
  selector: 'via-pesquisa-cnpj',
  templateUrl: './pesquisa-cnpj.component.html',
  styleUrls: ['./pesquisa-cnpj.component.css']
})
export class PesquisaCnpjComponent implements OnInit {

  cnpjFormGroupName: string = 'cnpj';

  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dynamicModalService: DynamicModalRequestService) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      cnpj: ['', [Validators.required]]
    });
  }

  get cnpj(): string {
    const maskedCnpj = this.formGroup.get(this.cnpjFormGroupName).value as string;
    return maskedCnpj.replace(/[^\d]+/g, '');
  }

  get cnpjCtrl(): UntypedFormControl {
    return this.formGroup.get(this.cnpjFormGroupName) as UntypedFormControl;
  }

  onSubmit(): void {
    const consultarPjFeature = {
      id: `ConsultarPessoaJuridica_${this.cnpj}`,
      title: 'Pessoa Jurídica - Consultar',
      class: ConsultaPjComponent,
      parametros: {
        cnpj: this.cnpj,
        isModal: true
      }
    } as FeatureAction;

    this.dynamicModalService.abrirModal(consultarPjFeature);
  }
}
