import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: 'app-alerta-cib',
	templateUrl: './alerta-cib.component.html',
	styleUrls: ['./alerta-cib.component.css'],	
})
export class AlertaCibComponent {

	@Output() resultado = new EventEmitter<string>();

	responder(resposta:string) {
		this.resultado.next(resposta);
	}	
}