import { CcitrBase } from './../../base/CcitrBase.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { LoadingService, Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import currency from 'currency.js';
import { isEmpty, isEqual, isNil } from 'lodash';
import { Observable, Subscription, throwError } from 'rxjs';
import { Componente } from 'src/app/cobranca/models/credito-tributario';
import { CreditoTributarioCcitr } from 'src/app/cobranca/models/credito-tributario-ccitr';
import { isWarning } from 'src/app/cobranca/models/erro-padrao';
import { ParametrosPesquisaCcitr } from 'src/app/cobranca/models/parametros-pesquisa-ccitr';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CONSTANTS } from './../../constants';
import { CCITRService } from './../../services/ccitr.service';
import { CcitrPagamentosDarfDetalheDTO, CcitrPagamentosDarfDTO } from './../pagamentos-darf/model/ccitr-pagamentos-darf';
import { ParametroComponenteDebitoCcitr } from './models/parametros-pesquisa';

@Component({
  templateUrl: 'aloca-pagamento.component.html',
  styleUrls: ['aloca-pagamento.component.css'],
  providers: [ScopedMessageService]
})
export class CcitrAlocaPagamentoComponent extends CcitrBase implements OnInit, OnDestroy, IDynamicPanel {

  @Input() cpfContribuinte: string;
  @Input() cnpjContribuinte: string;
  @Input() cib: string;
  @Input() componente: Componente;
  @Input() credito: CreditoTributarioCcitr;
  @Input() parametrosPesquisa: ParametrosPesquisaCcitr;
  @Output() onError: EventEmitter<void> = new EventEmitter();

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  parametros: ParametroComponenteDebitoCcitr;
  pagamentosDarf: CcitrPagamentosDarfDTO[];
  valorParaAlocacao: number;
  stopLoading: boolean = false;

  titulo: string;
  scopeId: string;
  initError: Error;

  currencyMaskOptions = {
    thousands: '.',
    decimal: ',',
    prefix: '',
    allowNegative: false,
    nullable: false,
    min: 0,
    max: null,
    precision: 2
  };

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  isAtualizacaoPagamentos = false;

  pagamentosDarfObservable$: Observable<CcitrPagamentosDarfDTO[]>;;

  private updateSubscription: Subscription;
  private featureEventSub: Subscription;

  constructor(
    private messageService: ScopedMessageService,
    private ccitrService: CCITRService,
    private loadingService: LoadingService,
    private menuFeatureActionsService: MenuFeatureActionsService) {
      super();
    }

  public init(_ni: string, params?: OptionalParams): void {
    super.init(_ni, params);
    this.parametros = params as ParametroComponenteDebitoCcitr;
    this.cpfContribuinte = this.parametros.cpfContribuinte;
    this.cnpjContribuinte = this.parametros.cnpjContribuinte;
    this.credito = this.parametros.credito;
    this.componente = this.parametros.componente;
    this.parametrosPesquisa = this.parametros.parametrosPesquisa;
    this.cib = this.parametros.nirf;
  }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
    this.titulo = `CCITR - Alocação de Pagamento - CT: ${this.credito.numeroCt} - Componente: ${this.componente.numero}`;

    this.updateSubscription = this.ccitrService.notificarAtualizacaoListaCreditosEmitter.subscribe(creditos => {
      if (!isEmpty(creditos) && this.credito) {
        const ct = this.credito.numeroCt;
        this.credito = creditos.find(credito => isEqual(this.credito.numeroCt, ct));
        this.updateSubscription?.unsubscribe();
      }
    });

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        const feature = featureEvent.featureAction;
        if (featureEvent.active == false && feature.id == 'CcitrConsultar' && feature.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharAlocacaoPagamento();
          this.featureEventSub?.unsubscribe();
        }
      }
    );

    this.obterPagamentos();
  }

  ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe();
    this.featureEventSub?.unsubscribe();
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(item => item.show = show);
  }

  isValorParaAlocacaoInvalido(valorParaAlocacao: number): boolean {
    return isNil(valorParaAlocacao) || valorParaAlocacao <= 0;
  }

  onAlocarPagamento(pagamentoAlocavel: CcitrPagamentosDarfDTO): void {

    this.messageService.dismissMessages();
    this.loadingService.show();

    let cib = this.cib;
    let numeroCT = this.credito.numeroCt;
    let numeroPagamento = pagamentoAlocavel.numeroPagamento;
    let numeroComponente = this.componente.numero;
    let ni = this.cnpjContribuinte ? this.cnpjContribuinte : this.cpfContribuinte;

    let valorAlocar =  currency(pagamentoAlocavel.valorParaAlocacao).toString();


    this.ccitrService.alocarPagamento(cib,
                                     numeroCT,
                                     numeroPagamento,
                                     numeroComponente,
                                     valorAlocar,
                                     ni)
      .subscribe(
      _sucesso => {
        this.messageService.showInfo('Alocação de pagamento realizada com sucesso');
        this.isAtualizacaoPagamentos = true;
        this.obterPagamentos();
      },
      (err: HttpErrorResponse) => {
      if(isWarning(err.error)) {
        this.messageService.showWarning(err.error.message);
        return null;
      } else {
        this.initError = err;
        this.messageService.showErrorException('Erro ao realizar a alocação do pagamento', err);
      }
    }
    ).add(this.loadingService.hide());
  }

  private obterPagamentos(): void {
    {

      let tipoLancamento = this.parametros.credito.tipoLancamento.substring(0,2);

      this.ccitrService.obterPagamentosDarf(
          this.cib,
          tipoLancamento,
          this.parametros.cpfContribuinte,
          this.parametros.cnpjContribuinte,
          this.parametros.credito.numeroCt,
          true /* paraAlocacao */)
      .subscribe(
        response => {
          try{
            this.pagamentosDarf = response;
            if (isEmpty(response)) {
              this.messageService.showInfo('Nenhum registro encontrado');
            }
            if (this.isAtualizacaoPagamentos) {
              this.ccitrService.atualizarListagemCreditos(this.parametrosPesquisa);
            }

          } catch(e) {
            this.initError = e;
            this.messageService.showErrorException('Ocorreu um erro ao consultar Pagamentos em CCITR Alocação Pagamento', e);
          }
        },
        (err: HttpErrorResponse) => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
          return null;
        } else {
          this.initError = err;
          this.onError.emit();
          this.messageService.showErrorException("Ocorreu um erro ao consultar Pagamentos em CCITR Alocação Pagamento ", err);
          return throwError(err);
        }
        }).add(() => {
          this.stopLoading = true;
     })

    }
  }

   private fecharAlocacaoPagamento(): void {
    const feature = {
      id: `CCITR_AlocarPagamentos_${this.credito.numeroCt}_componente_${this.componente.numero}`,
      class: CcitrAlocaPagamentoComponent,
      title: 'CCITR - Alocar Pagamentos',
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO
    } as FeatureAction;

    this.menuFeatureActionsService.deactivate(feature);
  }
}
