import { CcitrAlocaPagamentoComponent } from './components/aloca-pagamento/aloca-pagamento.component';
import { DetalheCCITRComponentesConsolidarCTComponent } from './components/consolidar-ct/dados-consolidar-ct/table-componentes/detalhe/detalhe-componentes-dados-consolidar-ct.component';
import { DetalheCCITRDadosConsolidarCTComponent } from './components/consolidar-ct/dados-consolidar-ct/detalhe/detalhe-dados-consolidar-ct.component.component';
import { RecuperaCTDepuradoComponent } from './components/recupera-ct-depurado/recupera-ct-depurado.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedSuiteRfbModule } from '../../../shared/shared-suite-rfb.module';
import { CcitrSuiteRfbModule } from './ccitr-suite-rfb.module';
import { FiltrosPesquisaCcitrComponent } from './components/filtros-pesquisa-ccitr/filtros-pesquisa-ccitr.component';
import { CCITRService } from './services/ccitr.service';
import { CcitrImoveisDoContribuinteComponent } from './components/imoveis-do-contribuinte/ccitr-imoveis-do-contribuinte.component';
import { DetalheCcitrImoveisDoContribuinteComponent } from './components/imoveis-do-contribuinte/detalhe/detalhe-ccitr-imoveis-do-contribuinte.component.component';
import { ConsultaPagamentosTDAComponent } from './components/consulta-pagamentos-tda/consulta-pagamentos-tda.component';
import { CcpfConsultaComponentesPagamentoTDATableComponent } from './components/consulta-pagamentos-tda/table-componentes/consulta-componentes-pagamento-tda-table.component';
import { ConsultaEventosComponent } from './components/eventos/consulta-eventos.component';
import { DetalheCCITRConsultaEventosComponent } from './components/eventos/detalhe/detalhe-consulta-eventos.component';
import { CcitrConsultaCompensacoesComponent } from './components/consulta-compensacoes/ccitr-consulta-compensacoes.component';
import { CcitrConsultaCreditosComponent } from './components/consulta-creditos/ccitr-consulta-creditos.component';
import { CcitrConsultaCreditoComponentesTableComponent } from './components/consulta-creditos/table-componentes/consulta-credito-componentes-table.component';
import { CcitrConsultaCreditoPagamentosTableComponent } from './components/consulta-creditos/table-pagamentos/consulta-credito-pagamentos-table.component';
import { CcitrPagamentosDarfComponent } from './components/pagamentos-darf/ccitr-pagamentos-darf.component';
import { DetalheCcitrPagamentosDarfComponent } from './components/pagamentos-darf/detalhe/detalhe-ccitr-pagamentos-darf.component';
import  AlteraVencimentoCTComponent  from './components/altera-vencimento-ct/altera-vencimento-ct.component';
import { CCITRTransferenciaCtComponent } from './components/transferencia-ct/ccitr-transferencia-ct.component';
import { CcitrImpedirAlocacaoAutomaticaPagamentoComponent } from './components/impedir-alocacao-automatica-pagamento/ccitr-impedir-alocacao-automatica-pagamento.component';
import ConsolidaCTComponent from './components/consolidar-ct/consolidar-ct.component';
import { ConsultaConsolidarCTComponent } from './components/consolidar-ct/dados-consolidar-ct/dados-consolidar-ct.component';
import { ConsultaComponentesConsolidaCTComponent } from './components/consolidar-ct/dados-consolidar-ct/table-componentes/componentes-dados-consolidar-ct.component';
import { CCITRDemonstrativoQuotasComponent } from './components/demonstrativo-quotas/ccitr-demonstrativo-quotas.component';
import { EventosFiltrosCCITR } from './components/eventos-filtros/eventos-filtros.component';
import { CodigoDescricaoPipe } from './pipes/codigo-descricao.pipe';
import { CcitrAlocaPagamentoDetalheAlocacoesComponent } from './components/aloca-pagamento/detalhe/aloca-pagamento-detalhe-alocacoes.component';
import { CCITRAlocacaoDebitoComponent } from './components/pagamento/alocacao-debito/alocacao-debito.component';
import { CCITRAlocacaoDebitoTableComponentesComponent } from './components/pagamento/alocacao-debito/table-componentes/alocacao-debito-table-componentes.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { AlertaMensagemCibNumericoComponent } from './components/alerta-cib/alerta-mensagem-cib-numerico.component';

@NgModule({
  declarations: [
    CcitrImoveisDoContribuinteComponent,
    FiltrosPesquisaCcitrComponent,
    ConsultaPagamentosTDAComponent,
    CcpfConsultaComponentesPagamentoTDATableComponent,
    ConsultaEventosComponent,
    DetalheCCITRConsultaEventosComponent,
    RecuperaCTDepuradoComponent,
    DetalheCcitrImoveisDoContribuinteComponent,
    CcitrConsultaCompensacoesComponent,
    CcitrConsultaCreditosComponent,
    CcitrConsultaCreditoComponentesTableComponent,
    CcitrConsultaCreditoPagamentosTableComponent,
    CcitrPagamentosDarfComponent,
    DetalheCcitrPagamentosDarfComponent,
    AlteraVencimentoCTComponent,
    CCITRTransferenciaCtComponent,
    CcitrImpedirAlocacaoAutomaticaPagamentoComponent,
    ConsultaComponentesConsolidaCTComponent,
    ConsultaConsolidarCTComponent,
    ConsolidaCTComponent,
    CCITRAlocacaoDebitoComponent,
    CCITRAlocacaoDebitoTableComponentesComponent,
    CCITRDemonstrativoQuotasComponent,
    EventosFiltrosCCITR,
    DetalheCCITRDadosConsolidarCTComponent,
    DetalheCCITRComponentesConsolidarCTComponent,
    CodigoDescricaoPipe,
    CcitrAlocaPagamentoDetalheAlocacoesComponent,
    CcitrAlocaPagamentoComponent,
    AlertaMensagemCibNumericoComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    CcitrSuiteRfbModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // BsModalRef,
    // ModalModule,
    SharedSuiteRfbModule,
    SharedModule,
    NgxCurrencyDirective
  ],
  exports: [
    ConsultaPagamentosTDAComponent,
    FiltrosPesquisaCcitrComponent,
    CcitrImoveisDoContribuinteComponent,
    DetalheCcitrImoveisDoContribuinteComponent,
    ConsultaEventosComponent,
    DetalheCCITRConsultaEventosComponent,
    RecuperaCTDepuradoComponent,
    CcitrConsultaCompensacoesComponent,
    CcitrConsultaCreditosComponent,
    CcitrConsultaCreditoComponentesTableComponent,
    CcitrConsultaCreditoPagamentosTableComponent,
    CcitrPagamentosDarfComponent,
    DetalheCcitrPagamentosDarfComponent,
    AlteraVencimentoCTComponent,
    CCITRTransferenciaCtComponent,
    CcitrImpedirAlocacaoAutomaticaPagamentoComponent,
    ConsolidaCTComponent,
    CCITRAlocacaoDebitoComponent,
    CCITRDemonstrativoQuotasComponent,
    EventosFiltrosCCITR,
    CodigoDescricaoPipe,
    CcitrAlocaPagamentoDetalheAlocacoesComponent,
    CcitrAlocaPagamentoComponent,
    AlertaMensagemCibNumericoComponent
  ],
  providers: [
    CCITRService
  ]
})
export class CcitrModule { }
