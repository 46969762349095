import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCibPipe'
})
export class FormatCibPipe implements PipeTransform {
  transform(cib: number) {
    return FormatCibPipe.formatCib(cib);
  }

  static formatCib: Function = (cib: any) => {
      if(cib){
        let tam = cib.length;
        let resultado = cib.substring(tam-1);
        for (let ini = tam - 4; ini > -3 ; ini-=3 ){
          resultado = cib.substring(ini > 0 ? ini : 0 , ini+3 ) 
            + (resultado.length>1 ? '.' : '-') 
            + resultado;
        }
        return resultado;
      }
  }
}