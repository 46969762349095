<div class="container-fluid" *ngFor="let ausencia of ausenciasPorCib | keyvalue">
  <div class="cib">
    <div class="ditr-toggle">
      <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
    </div>
    <div>CIB: {{ ausencia.key | formatCibPipe }}</div>
  </div>

  <ng-container *ngIf="rowToggle.show">
    <div class="cib-conteudo">
      <div class="table-responsive">
        <table suiteRfbTable>
          <thead>
            <tr>
              <th class="text-center">Exercícios Ausentes</th>
            </tr>
          </thead>
          <tbody>
            <tr class="vertical-align-middle" *ngFor="let apuracao of ausencia.value | currentPage: currentPage;">
              <div class="text-center">{{ apuracao }}</div>
            </tr>
          </tbody>
          <tfoot>
            <tr class="tabela-acoes text-left">
              <td>
                <via-pagination [itemAmount]="ausencia.value.length" [(page)]="currentPage"></via-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <button class="btn btn-default" (click)="onAbrirCafirDetalhe(ausencia.key)">CAFIR</button>
    </div>
  </ng-container>
</div>
