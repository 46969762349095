import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DasMensagensComponent } from './das-mensagens.component';
import { DasVisualizacaoComponent } from './das-visualizacao.component';
import { DasService } from './das.service';

@NgModule( {
    declarations: [
        DasMensagensComponent,
        DasVisualizacaoComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
    ],
    providers: [
        DasService
    ]
})
export class DasModule { }