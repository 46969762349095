/*
 * ngx-suite-rfb
 *
 * A biblioteca ngx-suite-rfb implementa um conjunto de componentes,
 * diretivas, pipes e serviços nativos do Angular com o objetivo de
 * promover o reuso e facilitar a implementação de aplicações frontend
 * que serão implantandas na Suíte RFB e, portanto, devem seguir os
 * Padrões de Interface Visual RFB.
 *
 * Documentação disponível em: https://dedat.gitpages.serpro/deat3/ngx-suite-rfb/
 *
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertMessage, AlertMessages, AlertMessageService } from '@serpro/ngx-suite-rfb';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'alerta-mensagem-cib-numerico',
  templateUrl: './alerta-mensagem-cib-numerico.component.html',
  styleUrls: ['./alerta-mensagem-cib-numerico.component.css'],
})

export class AlertaMensagemCibNumericoComponent implements OnInit, OnDestroy  {

  @Input() onlyFor: string;
  @Input() modal: boolean;

  messages: AlertMessages = [];
  lastMessages: string = "";

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private alertMessageService: AlertMessageService,
  ) {
  }
  
  public ngOnInit(): void {
    this.alertMessageService.getMessages$()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((messages: AlertMessages) => {
        let mensagensLocais = this.messages.length;
        this.messages = messages.filter(message =>
          this.onlyFor === message.target
        );
        if ( mensagensLocais < this.messages.length){
          setTimeout(()=> (document.getElementById(this.onlyFor).parentNode.parentNode as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'nearest'}),300);
        };
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public dismiss(message: AlertMessage): void {
    this.alertMessageService.dismiss(message);
  }

  public showMore(message: AlertMessage): void {
    this.alertMessageService.showMore(message);
  }
}