<div class="modal-header">
    <h4 class="modal-title pull-left">Atenção</h4>
    <span title="Fechar" aria-hidden="true" class="pull-right material-icons icon-24 cursor" (click)="responder('fechar')">close</span>
</div>
<div class="modal-body">
  <span>
    Consulte Créditos Tributários de CIB alfanumérico pelo Sief-Fiscel.
  </span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="responder('fechar')">Fechar</button>
</div>
