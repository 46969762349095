import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormatCnpjPipe } from '../pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from '../pipes/formata-cpf.pipe';
import { FormatProcessoPipe } from '../pipes/formata-processo.pipe';
import { FormatDataPipe } from '../pipes/formata-data.pipe';
import { FormatDataHoraPipe } from './formata-data-hora.pipe';
import { CEPPipe } from './cep.pipe';
import { MunicipioFormatPipe } from './municipio.pipe';
import { ExtraiDescricaoPipe } from './extrai-descricao.pipe';
import { FilterByAttributePipe } from './filter-by-attribute.pipe';
import { FormatCnoPipe } from './formata-cno.pipe';
import { FormatCpfCnpjPipe } from './formata-cpf-cnpj.pipe';
import { FormatNiPipe } from './formata-ni.pipe';
import { FormatPerdcompPipe } from './formata-perdcomp.pipe';
import { FormatCurrencyPipe } from './formata-valor.pipe';
import { SortByAttributePipe } from './sort-by-attribute.pipe';
import { SubclasseCNAEPipe } from './subclasse-cnae.pipe';
import { UAPipe } from './ua.pipe';
import { ArrayRemovePipe } from './array-remove.pipe';
import { GroupByPipe } from './group-by.pipe';
import { TipoNIDescricaoPipe } from './tipo-ni-descricao.pipe';
import { FormatCpfCnpjCeiPipe } from './formata-cpf-cnpj-cei.pipe';
import { FormatCibPipe } from './formata-cib.pipe';
import { VazioPipe } from './vazio/vazio.pipe';
import { TrustUrlPipe } from './trust-url.pipe';
import { TrimLeftPipe } from './trim-left/trim-left.pipe';
import { CodigoDescricaoPipe } from './codigo-descricao/codigo-descricao.pipe';
import { EnderecoInfoCadPipe } from './endereco-info-cad.pipe';


@NgModule({
  declarations: [
    FormatCurrencyPipe, 
    FormatCnpjPipe, 
    FormatCpfPipe, 
    FormatNiPipe, 
    FormatCibPipe,
    FormatCpfCnpjPipe, 
    FormatCpfCnpjCeiPipe,
    FormatProcessoPipe, 
    FormatPerdcompPipe, 
    FormatDataPipe,
    FormatDataHoraPipe,
    FormatCnoPipe, 
    ExtraiDescricaoPipe, 
    FilterByAttributePipe, 
    SortByAttributePipe, 
    CEPPipe,
    UAPipe,
    MunicipioFormatPipe,
    SubclasseCNAEPipe,
    ArrayRemovePipe,
    GroupByPipe,
    TipoNIDescricaoPipe,
    VazioPipe,
    TrustUrlPipe,
    TrimLeftPipe,
    CodigoDescricaoPipe,
    EnderecoInfoCadPipe
  ],
  exports: [
    FormatCurrencyPipe, 
    FormatCnpjPipe, 
    FormatCpfPipe, 
    FormatNiPipe, 
    FormatCibPipe,
    FormatCpfCnpjPipe, 
    FormatCpfCnpjCeiPipe,
    FormatProcessoPipe,
    FormatDataPipe,
    FormatDataHoraPipe,
    FormatPerdcompPipe, 
    FormatCnoPipe, 
    ExtraiDescricaoPipe, 
    FilterByAttributePipe, 
    SortByAttributePipe, 
    CEPPipe,
    UAPipe,
    MunicipioFormatPipe,
    SubclasseCNAEPipe,
    ArrayRemovePipe,
    GroupByPipe,
    TipoNIDescricaoPipe,
    VazioPipe,
    TrustUrlPipe,
    TrimLeftPipe,
    CodigoDescricaoPipe,
    EnderecoInfoCadPipe
  ]
})
export class PipesModule {

  static forRoot(): ModuleWithProviders<PipesModule> {
    return {
        ngModule: PipesModule,
        providers: [],
    };
  }
}
