import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConsultarReceitaComponent } from '../../consultar-receita.component';

@Component({
  selector: 'via-pesquisa-receita',
  templateUrl: './pesquisa-receita.component.html',
  styleUrls: ['./pesquisa-receita.component.css']
})
export class PesquisaReceitaComponent implements OnInit {

  receitaFormGroupName: string = 'receita';
  
  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private bsModalService: BsModalService) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      receita: ['', [Validators.required]]
    });
  }

  private get receita(): string {
    return this.formGroup.get(this.receitaFormGroupName).value as string;
  }

  get receitaCtrl(): UntypedFormControl {
    return this.formGroup.get(this.receitaFormGroupName) as UntypedFormControl;
  }

  onSubmit(): void {
    this.bsModalService.show(ConsultarReceitaComponent, {
      initialState: {
        codigo: this.receita
      }
    });
  }

}
