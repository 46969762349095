import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { isEmpty } from 'lodash';
import { isDateValid } from 'ngx-bootstrap/chronos';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FeatureAction } from 'src/app/shared/model/feature';
import { ConsultaTituloEleitorComponent } from '../../consulta-pf/consulta-titulo-eleitor/consulta-titulo-eleitor.component';
import { ParametrosPesquisaTituloEleitor } from '../../titulo-eleitor/model/parametros-pesquisa-titulo-eleitor';

@Component({
  selector: 'via-pesquisa-titulo-eleitor',
  templateUrl: './pesquisa-titulo-eleitor.component.html',
  styleUrls: ['./pesquisa-titulo-eleitor.component.css']
})
export class PesquisaTituloEleitorComponent implements OnInit {

  maxDate = new Date();

  formGroup: UntypedFormGroup;

  tituloFormGroupName = 'tituloEleitor';
  nomeFormGroupName = 'nome';
  nomeMaeFormGroupName = 'nomeMae';
  dataNascimentoFormGroupName = 'dataNascimento';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dynamicModalService: DynamicModalRequestService) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      tituloEleitor: [''],
      nome: [''],
      nomeMae: [''],
      dataNascimento: ['']
    });
  }

  private get numeroTitulo(): string {
    return this.formGroup.get(this.tituloFormGroupName).value as string;
  }

  private get nome(): string {
    return this.formGroup.get(this.nomeFormGroupName).value as string;
  }

  private get nomeMae(): string {
    return this.formGroup.get(this.nomeMaeFormGroupName).value as string;
  }

  private get dataNascimento(): Date {
    return this.formGroup.get(this.dataNascimentoFormGroupName).value as Date;
  }

  isValidForm(): boolean {
    return this.formGroup.valid && this.temCamposPreenchidos();
  }

  onSubmit(): void {
    const parametros: ParametrosPesquisaTituloEleitor = {
      numeroTitulo: this.numeroTitulo,
      nome: this.nome,
      nomeMae: this.nomeMae,
      dataNascimento: this.dataNascimento
    };

    const consultaTituloFeature = {
      id: 'ConsultaTituloEleitor',
      title: 'Consulta Titulo Eleitor',
      class: ConsultaTituloEleitorComponent,
      parametros: {
        parametrosPesquisa: parametros
      }
    } as FeatureAction;

    this.dynamicModalService.abrirModal(consultaTituloFeature);
  }

  private temCamposPreenchidos(): boolean {
    const isNomePreenchido = !isEmpty(this.nome);
    const isNomeMaePreenchido = !isEmpty(this.nomeMae);
    const isTituloPreenchido = !isEmpty(this.numeroTitulo);
    const isDataNascimentoPreenchida = isDateValid(this.dataNascimento);

    return isNomePreenchido
      || isNomeMaePreenchido
      || isTituloPreenchido
      || isDataNascimentoPreenchida;
  }
}
