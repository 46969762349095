import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@serpro/ngx-suite-rfb';
import { isEmpty, isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { Componente, CreditoTributario } from 'src/app/cobranca/models/credito-tributario';
import { ParametrosPesquisaCreditosTributarios } from 'src/app/cobranca/models/parametros-pesquisa';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CCPFService } from '../../services/ccpf.service';

@Component({
  selector: 'via-ccpf-demonstrativo-cotas',
  templateUrl: './demonstrativo-cotas.component.html',
  styleUrls: ['./demonstrativo-cotas.component.css'],
  providers: [ScopedMessageService]
})
export class CCPFDemonstrativoCotasComponent implements IDynamicPanel, OnInit, OnDestroy {

  @Input() cpf: string;
  @Input() credito: CreditoTributario;
  @Input() atendimento = false;
  @Input() parametrosPesquisa: ParametrosPesquisaCreditosTributarios;

  titulo: string;

  componentes: Componente[];

  formGroup: UntypedFormGroup;
  qtdCotasName = 'qtdCotas';

  scopeId: string;
  initError: Error;

  featureEventSub: Subscription;

  constructor(
    private loadingService: LoadingService,
    private messageService: ScopedMessageService,
    private ccpfService: CCPFService,
    private formBuilder: UntypedFormBuilder,
    private menuFeatureActionsService: MenuFeatureActionsService) { }

  init(_ni: string, params?: OptionalParams): void {
    this.cpf = params?.cpf;
    this.credito = params?.credito;
    this.atendimento = params?.atendimento ?? this.atendimento;
    this.componentes = this.credito?.componentes;
    this.parametrosPesquisa = params?.parametrosPesquisa;
    this.titulo = `CCPF - Alterar Demonstrativo de Quotas do CT: ${this.credito?.ct}`;

    this.formGroup = this.formBuilder.group({
      qtdCotas: ['', [Validators.required]]
    });

    this.scopeId = this.messageService.scopeId;

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        const feature = featureEvent.featureAction;
        if (featureEvent.active == false && feature.id == 'CcpfConsultar' && feature.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharDemonstrativoCotas();
          this.featureEventSub?.unsubscribe();
        }
      }
    );
  }

  ngOnInit(): void {
    this.ccpfService.notificarAtualizacaoListaCreditosEmitter.subscribe(creditos => {
      if (!isEmpty(creditos) && this.credito) {
        const ct = this.credito.ct;
        this.credito = creditos.find(credito => isEqual(credito.ct, ct));
        this.componentes = this.credito?.componentes;
        this.loadingService.hide();
      }
    });
  }

  ngOnDestroy(): void {
    this.featureEventSub?.unsubscribe();
  }

  onAlterarQtdCotas(): void {
    this.messageService.dismissMessages();
    this.loadingService.show();
    this.ccpfService.alterarQtdCotas(this.cpf, this.credito.ct, this.qtdCotas)
      .subscribe(
        _sucesso => {
          this.loadingService.hide();
          this.formGroup.reset();
          this.messageService.showInfo(`Cotas do Crédito Tributário ${this.credito.ct} alteradas com sucesso`);
          this.ccpfService.atualizarListagemCreditos(this.parametrosPesquisa);
        },
        error => {
          this.loadingService.hide();
          this.messageService.showErrorException(`Ocorreu um erro ao alterar a Quantidade de Cotas do Crédito Tributário ${this.credito.ct}`, error);
        }
      );
  }

  private get qtdCotas(): number {
    return this.formGroup.get(this.qtdCotasName).value as number;
  }

  private fecharDemonstrativoCotas(): void {
    const feature = {
      id: `CCPFDemonstrativoCotas_credito_${this.credito.ct}`,
      title: 'CCPF - Alterar Demonstrativo de Quotas',
      class: CCPFDemonstrativoCotasComponent,
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO
    } as FeatureAction;

    this.menuFeatureActionsService.deactivate(feature);
  }
}
