import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { LoadingService, ToggleComponent } from "@serpro/ngx-suite-rfb";
import { orderBy } from "lodash";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ComponenteCcitr, CreditoTributarioCcitr } from "src/app/cobranca/models/credito-tributario-ccitr";
import { isWarning } from "src/app/cobranca/models/erro-padrao";
import { ParametrosConsultaPagamentosDARF } from "src/app/cobranca/models/parametros-consulta-pagamentos_darf";
import { ParametrosPesquisaCcitr } from "src/app/cobranca/models/parametros-pesquisa-ccitr";
import { IDynamicPanel, OptionalParams } from "src/app/shared/dynamic-panel/panel-interface";
import { MenuFeatureActionsService } from "src/app/shared/menu-feature-actions/menu-feature-actions.service";
import { FeatureAction, TipoMenu } from "src/app/shared/model/feature";
import { ScopedMessageService } from "src/app/shared/services/scoped-message.service";
import { CcitrBase } from "../../../base/CcitrBase.component";
import { CCITRService } from "../../../services/ccitr.service";
import { CcitrPagamentosDarfDTO } from "../../pagamentos-darf/model/ccitr-pagamentos-darf";
@Component({
  templateUrl: './alocacao-debito.component.html',
  styleUrls: ['./alocacao-debito.component.css'],
  providers: [ScopedMessageService]
})
export class CCITRAlocacaoDebitoComponent extends CcitrBase implements IDynamicPanel, OnInit, OnChanges, OnDestroy {

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  initError: Error;

  private featureEventSub: Subscription;

  pagamento: CcitrPagamentosDarfDTO;
  scopeId: string;
  titulo: string;
  parametrosPesquisa: ParametrosConsultaPagamentosDARF;
  creditos: CreditoTributarioCcitr[];

  constructor(
    private ccitrService: CCITRService,
    private loadingService: LoadingService,
    private messageService: ScopedMessageService,
    private menuFeatureActionsService: MenuFeatureActionsService) {
    super();
  }

  init(ni: string, params?: OptionalParams): void {

    super.init(ni, params.parametrosPesquisa);

    this.pagamento = params?.pagamento as CcitrPagamentosDarfDTO;
    this.parametrosPesquisa = params?.parametrosPesquisa as ParametrosConsultaPagamentosDARF;
    this.titulo = `CCITR - Alocar Débito - Pagamento: ${this.pagamento.numeroPagamento}`;
    this.scopeId = this.messageService.scopeId;

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        const feature = featureEvent.featureAction;
        const parentFeatureId = `ccitr-consultarpagamentos${this.parametrosPesquisa.cpfContribuinte}`;
        if (featureEvent.active == false
          && (feature.id == 'CcitrConsultar' || feature.id == parentFeatureId)
          && feature.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharAlocacaoDebito();
          this.featureEventSub?.unsubscribe();
        }
      }
    );
    this.obterCreditosTributarios();
  }

  ngOnDestroy(): void {
    this.featureEventSub?.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  ngOnInit(): void {
  }

  private alocarDebito(item: { componente: ComponenteCcitr, valorAlocar: string }, credito: CreditoTributarioCcitr): void {
    this.messageService.dismissMessages();

    let cib = this.parametrosPesquisa.nirf;
    let numeroCT = credito.numeroCt;
    let numeroPagamento = this.pagamento.numeroPagamento;
    let numeroComponente =  item.componente.numero;
    let valorAlocar = item.valorAlocar;
    let ni;
    if(this.parametrosPesquisa.cpfContribuinte) {
      ni = this.parametrosPesquisa.cpfContribuinte;
    }
    else {
      ni = this.parametrosPesquisa.cnpjContribuinte;
    }

    this.loadingService.show();
    this.ccitrService.alocarPagamento(cib, numeroCT, numeroPagamento, numeroComponente, valorAlocar, ni)
      .subscribe(
        (val: void) => {
          this.messageService.showInfo('Débito alocado com sucesso', `Débito ${numeroCT} - Componente ${numeroComponente}`);
          this.obterCreditosTributarios();
        },
        (err: HttpErrorResponse) => {
          if(isWarning(err.error)) {
            this.messageService.showWarning(err.error.message);
          }
          else {
            this.initError = err;
            this.messageService.showErrorException(`Erro ao alocar débito ao pagamento ${numeroPagamento}`, err);
          }
        }
        ).add(() => {
          this.loadingService.hide();
      });
  }

  private obterCreditosTributarios(): void {

    let parametros = {
      nirf: this.parametrosPesquisa.nirf,
      cpfContribuinte: this.parametrosPesquisa.cpfContribuinte,
      cnpjContribuinte: this.parametrosPesquisa.cnpjContribuinte,
      indicadorCnpjBasico: this.parametrosPesquisa.indicadorCnpjBasico,
      tipoLancamento: this.parametrosPesquisa.tipoLancamento,
      vencidosAVencer: this.parametrosPesquisa.vencidosAVencer,
      exercicioInicial: this.parametrosPesquisa.exercicioInicial,
      exercicioFinal: this.parametrosPesquisa.exercicioFinal,
      atendimento: this.atendimento
    } as ParametrosPesquisaCcitr;

    this.ccitrService.consultarCreditosTributarios(parametros).pipe(
      map(resp => orderBy(resp, ['exercicio'], 'desc'))
    ).subscribe(
      (resp) => {
        this.creditos = resp;
        this.loadingService.hide();
      },
      (erro) => {
        if (isWarning(erro)) {
          this.messageService.showWarning(erro.message);
        }
        else {
          this.creditos = [];
          this.initError = erro;
          this.messageService.showErrorException("ERRO ", erro);
        }
        this.loadingService.hide();
      }
    );
  }

  private fecharAlocacaoDebito(): void {
    const feature = {
      id: `CCPF_Alocar_Debito_pagamento_${this.pagamento.numeroPagamento}`,
      title: 'CCPF - Alocar Débito',
      class: CCITRAlocacaoDebitoComponent,
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO
    } as FeatureAction;

    this.menuFeatureActionsService.deactivate(feature);
  }

}
